import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import {SignatureFieldComponent} from '@app-shared-components';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-event-form-text',
  standalone: true,
  imports: [CommonModule, SignatureFieldComponent, MatFormField, MatIcon, MatIconButton, MatInput, MatLabel, MatSuffix, ReactiveFormsModule],
  templateUrl: './event-form-text.component.html',
  styleUrls: ['./event-form-text.component.scss', '../event-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EventFormTextComponent),
    multi: true
  }]
})
export class EventFormTextComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() description: string;
  @Input() useDescAsLabel: boolean = false;
  @Input() formControl: FormControl<string>;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  protected value: string;

  private onChange?: (val: string) => void;
  private onTouched?: () => void;

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
    if(this.onChange) {
      this.onChange(this.value);
    }
  }
}
