import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { filter, first, map, take } from 'rxjs/operators';
import { AssetNamePipe } from '@app-pipes';
import {
  AssetSelectService,
  AuthService,
  DataService,
  LanguageService,
  SelectedTruckService,
  StorageService,
} from '@app-services';
import { Asset } from '@app-models';
import { Router } from '@angular/router';
import { Paths, StorageKeys } from '@app-enums';
import { MatSelectChange } from '@angular/material/select';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-asset-select',
  templateUrl: './asset-select.component.html',
  styleUrls: ['./asset-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetSelectComponent {

  public availableAssets: Asset[];

  public readonly filteredAssets$ = new BehaviorSubject<Asset[]>(null);
  public readonly assetForm$ = new BehaviorSubject<UntypedFormGroup>(null);
  public readonly suggestedTruck$ = new BehaviorSubject<Asset>(null);
  private readonly automaticSelect = true;

  constructor(
    private readonly assetSelectService: AssetSelectService,
    private readonly selectedTruckService: SelectedTruckService,
    private readonly dataService: DataService,
    private readonly assetNamePipe: AssetNamePipe,
    private readonly router: Router,
    private readonly assetName: AssetNamePipe,
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly afs: AngularFirestore,
  ) {
    this.automaticSelect = this.router.getCurrentNavigation().extras.state?.automaticSelect;
  }

  async ionViewWillEnter() {
    this.initForm();
    this.getAllAssets();
  }

  // #region UI Responses

  /**
   * If user types in something, remove the selected asset
   *
   * @returns void
   */
  public onInput(): void {
    this.assetForm$.value.patchValue({ assetName: null });
  }

  /**
   * Preselected asset used to login into it.
   *
   * @param asset Asset
   *
   * @returns void
   */
  public async continueWithAsset(asset: Asset): Promise<void> {
    this.assetSelectService
      .loginToAsset(asset.assetId)
      .pipe(first())
      .subscribe(() => {
        this.handleAssetSelection(asset);
      });
  }

  /**
   * After asset is selected, User will be 'logged in' into it.
   *
   * @returns void
   */
  public onSelectAsset(): void {
    let asset = this.assetForm$.value.get('assetName').value as Asset;
    if (!asset) {
      asset = this.filteredAssets$.value.find((x) => {
        return this.assetName.transform(x).toLowerCase() === this.assetForm$.value.value.asset.toLowerCase();
      }) as Asset;
    }
    this.assetSelectService
      .loginToAsset(asset.assetId)
      .pipe(first())
      .subscribe(() => {
        this.handleAssetSelection(asset);
      });
  }

  // #endregion

  // #region Methods

  /**
   * Store displayName (licencePlate) into storage.
   * If no information available, retrieve it from FireStore first.
   *
   * @param asset Asset
   *
   * @returns void
   */
  private saveDisplayName(asset: Asset): void {
    if (asset.displayName) {
      this.selectedTruckService.licencePlate = asset.displayName;
      this.storageService.set(StorageKeys.TRUCK_DISPLAY_NAME, asset.displayName);
    } else {
      this.selectedTruckService
        .getDisplayNameByAssetId(asset.assetId)
        .pipe(first())
        .subscribe((response) => {
          const truck = response as Asset;
          this.selectedTruckService.licencePlate = this.assetName.transform(truck);
          this.storageService.set(StorageKeys.TRUCK_DISPLAY_NAME, this.selectedTruckService.licencePlate);
        });
    }
  }

  /**
   * Save trailer info for the truck selected.
   * Information will be retrieved from FireStore.
   * Upon it, the User will be redirected to the Chat page.
   *
   * @param truck Asset
   *
   * @returns void
   */
  private saveTrailerInfo(truck: Asset): void {
    this.selectedTruckService
      .getAssetDetailsById(truck.assetId)
      .pipe(take(1))
      .subscribe((truckDetails) => {
        const currentTruck = truckDetails as Asset;
        this.selectedTruckService.trailerId =
          currentTruck.assetIDAfter && currentTruck.assetIDAfter.length > 0 ? currentTruck.assetIDAfter : null;
        this.storageService.set(StorageKeys.TRAILER_ID, this.selectedTruckService.trailerId);
        this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
      });
  }

  /**
   * Initialize the form for asset selection
   *
   * @returns void
   */
  private initForm(): void {
    this.assetForm$.next(this.assetSelectService.getAssetSelectForm());
    this.assetForm$.value.get('asset').valueChanges.subscribe((input) => {
      this.handleAutocompleteInputChange(input);
    });
  }

  /**
   * When user selects language from the mat-select set it as his lang, and change app lang
   *
   * @param value MatSelectChange
   */
  public onLanguageChange(value: MatSelectChange): void {
    this.languageService.setLanguage(value.value.code);
  }

  /**
   * Get all assets by http request.
   *
   * @returns void
   */
  private getAllAssets(): void {
    this.dataService.assets
      .getAll()
      .pipe(first())
      .subscribe((assets) => this.handleAvailableAssetsList(assets));
  }

  /**
   * Get last user info in order to suggest the truck for him.
   *
   * @returns Promise<any>
   */
  private async getLastUser(): Promise<any> {
    const user = await this.authService.userData;
    const lastUid = this.storageService.get(StorageKeys.LAST_USER_ID);
    const savedUserId = this.storageService.get(StorageKeys.USER_ID);
    this.afs
      .collection('/drivers/', (ref) => ref.where('uid', '==', user?.uid ?? savedUserId))
      .snapshotChanges()
      .pipe(
        filter((x) => x[0]?.payload.doc.metadata.fromCache === false),
        first(),
        map((x) => x[0]?.payload.doc.data()),
      )
      .subscribe((firebaseUser: any) => {
        if (firebaseUser) {
          // this.authService.newUserData = firebaseUser;
        }
        if (firebaseUser && firebaseUser.iso639LocaleCode) {
          this.languageService.setLanguage(firebaseUser.iso639LocaleCode);
        }
        if (firebaseUser.assistantAssetID && firebaseUser.uid === lastUid && lastUid === savedUserId) {
          const lastTruckId = firebaseUser.assistantAssetID;
          this.suggestedTruck$.next(this.filteredAssets$.value.find((x) => x.assetId === lastTruckId));
          if (this.automaticSelect) {
            this.continueWithAsset(this.suggestedTruck$.value);
          }
        } else {
          this.suggestedTruck$.next(null);
          this.storageService.set(StorageKeys.LAST_USER_ID, firebaseUser.uid);
        }
      });
  }

  // #endregion

  // #region Handlers

  /**
   * Filter only trucks from all assets, then set their displayName,
   * sort them in alphabetical order, and display em inside
   * the autocomplete input on asset select page.
   *
   * @param assetsList Asset[]
   *
   * @return void
   */
  private handleAvailableAssetsList(assetsList: Asset[]): void {
    this.assetSelectService.mapAssetsDisplayName(assetsList);
    this.assetSelectService.sortAssetsByDisplayName(assetsList);
    this.availableAssets = assetsList;
    this.filteredAssets$.next(this.availableAssets);
    this.getLastUser();
  }

  /**
   * After asset is being selected, asset ID will be stored into local storage.
   * Then, display name and trailer info will be handled and saved as well.
   *
   * @param truck Asset
   *
   * @returns void
   */
  private handleAssetSelection(truck: Asset): void {
    this.selectedTruckService.saveAssetID(truck.assetId);
    this.storageService.set(StorageKeys.LAST_USED_TRUCK_ID, truck.assetId);
    this.saveDisplayName(truck);
    this.saveTrailerInfo(truck);
  }

  /**
   * This method will handle any autocomplete input change.
   *
   * @param input string | Asset
   *
   * @returns void
   */
  private handleAutocompleteInputChange(input: string | Asset): void {
    if (input.hasOwnProperty('displayName')) {
      const assetNameConverted = this.assetNamePipe.transform(input);
      this.assetForm$.value.patchValue({ asset: assetNameConverted });
      this.assetForm$.value.patchValue({ assetName: input });
    } else {
      const search = input.toString().toLowerCase();
      const filtered = this.availableAssets.filter((x) => x.displayName.toString().toLowerCase().indexOf(search) !== -1);
      this.filteredAssets$.next(filtered);
    }
  }

  // #endregion
}
