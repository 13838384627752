import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {AuthService, LoaderService, LoginService, ToasterService} from '@app-services';
import {LoaderConsumersKeys, Paths, ToasterTypes} from '@app-enums';
import {Router} from '@angular/router';
import {BarcodeScanner} from '@capacitor-mlkit/barcode-scanning';
import {Platform} from '@ionic/angular';
import {User} from '@libs/shared/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  protected readonly scanning$ = new BehaviorSubject<boolean>(false);
  protected readonly showQRScanButton$ = new BehaviorSubject<boolean>(false);

  protected readonly loginForm$ = new BehaviorSubject<UntypedFormGroup>(null);
  protected readonly passwordInputType$ = new BehaviorSubject<string>('password');

  constructor(
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit() {
    this.initLoginForm();
    this.checkPlatform();
  }

  // #region UI Responses

  /**
   * Start scanning, make app transparent so the scanner is visible
   *
   * @returns Promise<void>
   */
  protected async onScanQrCode(): Promise<void> {
    const permissions = await BarcodeScanner.requestPermissions();
    if(permissions.camera !== 'granted') {
      this.toasterService.showMessage(this.translate.instant('Login.ScanPermissionError'), ToasterTypes.ERROR, 2500);
      return;
    }
    await BarcodeScanner.addListener('barcodeScanned', result => {
      document.querySelector('body').classList.remove('scanner-active');
      let loginCredentials = JSON.parse(result.barcode.rawValue);
      this.scanning$.next(false);
      if(!('email' in loginCredentials) || !('pin' in loginCredentials)) {
        this.toasterService.showMessage(this.translate.instant('Login.ScanCodeError'), ToasterTypes.ERROR, 2500);
        return;
      }
      // give some time for the page to come back
      setTimeout(() => {
        this.attemptLogin(loginCredentials.email, loginCredentials.pin);
      }, 100);
    });
    await BarcodeScanner.addListener('scanError', result => {
      document.querySelector('body').classList.remove('scanner-active');
      this.scanning$.next(false);
      this.toasterService.showMessage(this.translate.instant(result.message), ToasterTypes.ERROR, 2500);
    });

    document.querySelector('body').classList.add('scanner-active');
    this.scanning$.next(true);
    await BarcodeScanner.startScan();
  }

  /**
   * Close the scanner
   *
   * @returns void
   */
  protected async onCloseScanner(): Promise<void> {
    await BarcodeScanner.removeAllListeners();
    await BarcodeScanner.stopScan();
    this.scanning$.next(false);
    document.querySelector('body').classList.remove('scanner-active');
  }

  /**
   * Handles Login button.
   *
   * @returns void
   */
  protected onLogin(): void {
    const password = this.loginForm$.value.get('pinCode').value;
    let email = this.loginForm$.value.get('email').value;
    this.attemptLogin(email, password);
  }

  /**
   * Attempt to login as user with email and password
   *
   * @param email string
   * @param password string
   *
   * @returns void
   */
  private attemptLogin(email: string, password: string): void {
    this.loaderService.showLoader(LoaderConsumersKeys.LOGIN);
    this.authService.login(email, password).subscribe({
      next: (response) => {
        this.handleSuccessfulLogin(response);
      },
      error: (error) => {
        this.handleUnsuccessfulLogin(error);
      },
    });
  }

  /**
   * Toggle password input type. Possible values are 'password' and 'text'
   */
  protected onChangePasswordType(): void {
    if (this.passwordInputType$.value === 'password') {
      this.passwordInputType$.next('text');
    } else {
      this.passwordInputType$.next('password');
    }
  }

  // #endregion

  // #region Methods

  /**
   * Check if it's pwa, if so, hide the loginQR button
   *
   * @returns void
   */
  private checkPlatform(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        BarcodeScanner.isSupported()
          .then(supported => {
            this.showQRScanButton$.next(supported.supported);
          })
          .catch(() => this.showQRScanButton$.next(false));
      } else {
        this.showQRScanButton$.next(false);
      }
    });
  }

  /**
   * Initialize login form.
   *
   * @returns void
   */
  private initLoginForm(): void {
    this.loginForm$.next(this.loginService.getLoginForm());
  }

  // #endregion

  //#region Handlers

  /**
   * Handle a successful Login scenario.
   *
   * @param response Response from login EP
   *
   * @returns void
   */
  private handleSuccessfulLogin(response: User): void {
    this.authService.setLoggedInState(response);
    this.router.navigateByUrl(Paths.HOME_SELECT_ASSET, { replaceUrl: true, state: { automaticSelect: true } });
  }

  /**
   * Handle an unsuccessful Login scenario.
   *
   * @param error any
   *
   * @returns void
   */
  private handleUnsuccessfulLogin(error: any): void {
    this.authService.isLoggedIn = false;
    this.loaderService.hideLoader(LoaderConsumersKeys.LOGIN);
    this.toasterService.showMessage(error, ToasterTypes.ERROR);
  }

  //#endregion
}
