import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, SecurityContext, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CameraService } from '@app-services';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Paths } from '@app-enums';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-paint',
  templateUrl: './paint.component.html',
  styleUrls: ['./paint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaintComponent implements AfterViewInit {

  @ViewChild('imageCanvas', { static: false }) canvas: any;
  public selectedColor$ = new BehaviorSubject<string>('red');
  public colors$ = new BehaviorSubject<string[]>(['black', 'silver', 'white', 'yellow', 'orange', 'red', 'blue', 'green']);
  public canvasElement: any;
  public lineWidth = 5;

  private saveX: number;
  private saveY: number;
  private background: any;
  private drawing = false;

  constructor(
    private platform: Platform,
    private cameraService: CameraService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer
  ) { }

  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    const imageRatio = this.cameraService.chatPhoto.height / this.cameraService.chatPhoto.width;
    this.initCanvas(imageRatio);
  }

  // #region UI Responses

  /**
   * Go back to chat-preview page.
   *
   * @returns void
   */
  public onBack(): void {
    this.router.navigateByUrl(Paths.HOME_CHAT_PREVIEW, { replaceUrl: true });
  }

  /**
   * Set selected color that will be in use.
   *
   * @param color string
   *
   * @returns void
   */
  public onSelectColor(color: string): void {
    this.selectedColor$.next(color);
  }

  /**
   * This method will be executed on each drawing start.
   *
   * @param event Event
   *
   * @returns void
   */
  public onStartDrawing(event): void {
    this.drawing = true;
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    this.saveX = event.changedTouches[0].pageX - canvasPosition.x;
    this.saveY = event.changedTouches[0].pageY - canvasPosition.y;
  }

  /**
   * This method will be executed when the User is moving finger over image.
   *
   * @param event Event
   *
   * @returns void
   */
  public onMoved(event): void {
    if (!this.drawing) {
      return;
    }
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    const ctx = this.canvasElement.getContext('2d');
    const currentX = event.changedTouches[0].pageX - canvasPosition.x;
    const currentY = event.changedTouches[0].pageY - canvasPosition.y;
    ctx.lineJoin = 'round';
    ctx.strokeStyle = this.selectedColor$.value;
    ctx.lineWidth = this.lineWidth;
    ctx.beginPath();
    ctx.moveTo(this.saveX, this.saveY);
    ctx.lineTo(currentX, currentY);
    ctx.closePath();
    ctx.stroke();
    this.saveX = currentX;
    this.saveY = currentY;
  }

  /**
   * This method will be executed when drawing is finished.
   *
   * @returns void
   */
  public onEndDrawing(): void {
    this.drawing = false;
  }

  /**
   * Set canvas background using image that we edit inside this component.
   *
   * @returns void
   */
  public onSetBackground(): void {
    let image = new Image();
    image.src = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this.cameraService.chatPhoto.content);
    image.onload = () => {
      const ctx = this.canvasElement.getContext('2d');
      ctx.drawImage(image, 0, 0, this.canvasElement.width, this.canvasElement.height);
      this.cdr.detectChanges();
    };
  }

  /**
   * Save image changes inside canvas.
   *
   * @returns void
   */
  public onSave(): void {
    const dataUrl = this.canvasElement.toDataURL();
    this.cameraService.chatPhoto.content = dataUrl;
    this.router.navigateByUrl(Paths.HOME_CHAT_PREVIEW, { replaceUrl: true });
  }

  //#endregion

  // #region Methods

  /**
   * Initialize canvas.
   *
   * @param imageRatio
   *
   * @returns void
   */
  private initCanvas(imageRatio: number): void {
    if (imageRatio <= 1) {
      this.canvasElement.width = this.platform.width() + '';
      this.canvasElement.height = this.platform.width() * imageRatio;
    } else {
      this.canvasElement.height = '400';
      this.canvasElement.width = Math.round((1 / imageRatio) * 400) + '';
    }
    this.onSetBackground();
  }

  // #endregion
}
