import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import {Contacts, GetContactsResult, PhoneType} from '@capacitor-community/contacts';
import { TranslateService } from '@ngx-translate/core';
import { ToasterTypes } from '@app-enums';
import { PhonebookEntry } from '@app-models';
import { ToasterService } from '@app-services';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private readonly projection = {
    // Specify which fields should be retrieved.
    name: true,
    phones: true,
    postalAddresses: true,
  };

  constructor(
    private androidPermissions: AndroidPermissions,
    private toasterService: ToasterService,
    private translationService: TranslateService,
  ) {}

  /**
   * Check if all contacts are synced to the phone
   *
   * @param phoneBook phonebookListEntity[]
   *
   * @returns Promise<boolean>
   */
  public async checkIfSynced(phoneBook: PhonebookEntry[]): Promise<boolean> {
    let res: GetContactsResult;
    await Contacts.getContacts({ projection: this.projection })
      .then((contacts) => {
        res = contacts;
      })
      .catch((err) => {});
    if (res) {
      let existingContacts: any[] = [];
      let contactExists: any;
      phoneBook.forEach((element) => {
        contactExists = res.contacts.find((x) => {
          if (x.phones) {
            return (x.phones[0].number ?? '').split(' ').join('').split('-').join('') === element.phoneNumber;
          } else {
            return false;
          }
        });
        if (contactExists) {
          existingContacts.push(contactExists);
        }
      });
      return phoneBook.length === existingContacts.length;
    } else {
      return null;
    }
  }

  /**
   * Check/Request permissions for contacts
   *
   * @returns Promise<any>
   */
  public checkPermissions(): Promise<any> {
    return this.androidPermissions.requestPermissions([
      this.androidPermissions.PERMISSION.WRITE_CONTACTS,
      this.androidPermissions.PERMISSION.READ_CONTACTS,
    ]);
  }

  /**
   * Ask for permissions and then sync contacts
   *
   * @param phoneBook phonebookListEntity[]
   *
   * @returns Promise<void>
   */
  public async syncContacts(phoneBook: PhonebookEntry[]): Promise<void> {
    await this.permissionsHandler(phoneBook);
  }

  /**
   * Resolve required permissions for contact read and write
   *
   * @param phoneBook phonebookListEntity[]
   *
   * @returns Promise<void>
   */
  public async permissionsHandler(phoneBook: PhonebookEntry[]): Promise<void> {
    await this.androidPermissions
      .requestPermissions([
        this.androidPermissions.PERMISSION.WRITE_CONTACTS,
        this.androidPermissions.PERMISSION.READ_CONTACTS,
      ])
      .then(async (value) => {
        if (value.hasPermission) {
          await this.sync(phoneBook);
        } else {
          this.toasterService.showMessage(
            this.translationService.instant('General.Messages.NoContactPermission'),
            ToasterTypes.WARNING,
          );
        }
      });
  }

  /**
   * Add contacts to the phone
   *
   * @param phoneBook phonebookListEntity[]
   *
   * @returns Promise<void>
   */
  public async sync(phoneBook: PhonebookEntry[]): Promise<void> {
    await Contacts.getContacts({ projection: this.projection }).then((contacts) => {
      phoneBook.forEach(async (element) => {
        const contactExists = contacts.contacts.find((x) => {
          if (x.phones) {
            return (x.phones[0]?.number ?? '').split(' ').join('').split('-').join('') === element.phoneNumber;
          } else {
            return false;
          }
        });
        if (!contactExists) {
          await Contacts.createContact({
            contact: {
              name: {
                given: element.name.split(' ')[0] ?? element.name,
                family: element.name.split(' ')[1] ?? '',
              },
              organization: {
                company: element.companyName,
              },
              phones: [
                {
                  type: PhoneType.Mobile,
                  number: element.phoneNumber,
                },
              ],
            },
          });
        }
      });
    });
  }

  /**
   * Show toaster that data is already synced
   *
   * @returns void
   */
  public alreadySynced(): void {
    this.toasterService.showMessage(this.translationService.instant('Contacts.AllAlreadySynced'), ToasterTypes.WARNING);
  }
}
