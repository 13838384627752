import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CameraService, StorageService } from '@app-services';
import { ChatPhotoModes, CreatePdfModes, Paths, StorageKeys } from '@app-enums';
import { CreatePdfComponent } from '../../popups/create-pdf/create-pdf.component';
import { TrailerManagementComponent } from '../../popups/trailer-management/trailer-management.component';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonsComponent {

  public modes = ChatPhotoModes;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private storageService: StorageService,
    private cameraService: CameraService
  ) { }

  //#region UI Responses

  /**
   * Open trailer management popup.
   *
   * @returns void
   */
  public onOpenTrailerPopup(): void {
    this.dialog.open(TrailerManagementComponent);
  }

  /**
   * Open CreatePDF popup.
   *
   * @returns void
   */
  public onCreatePDF(): void {
    this.router.navigateByUrl(Paths.HOME_CREATE_PDF, { replaceUrl: true });
  }

  /**
   * Take a photo to be uploaded.
   *
   * @returns void
   */
  public onTakePhoto(mode: ChatPhotoModes): void {
    this.storageService.set(StorageKeys.CHAT_PHOTO_MODE, mode);
    this.cameraService.chatPhoto = null;
    this.cameraService.allChatPhotos = [];
    this.router.navigateByUrl(Paths.HOME_CHAT_PREVIEW, { replaceUrl: true });
  }
  //#endregion

}
