export interface TripStopTask {
  fields: Array<any>;
  failed: boolean;
  finished: boolean;
  skipped: boolean;
  started: boolean;
  type: string;
  values: Array<any>;
  finishedDatetime: Date;
}
