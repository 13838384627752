import { ChangeDetectionStrategy, Component } from '@angular/core';
import {Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {PushService} from '@app-services';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPopupComponent {

  protected showButton$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly platform: Platform,
    private readonly pushService: PushService,
    private readonly dialogRef: MatDialogRef<NotificationPopupComponent>
  ) {
    this.showButton$.next(this.platform.is('capacitor'));
  }

  protected goToSetting() {
    this.pushService.goToSetting();
    this.dialogRef.close();
  }
}
