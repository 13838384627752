import { FakeRepository } from '@libs/shared/data-access';
import { IEventTypeRepository } from './IEventTypeRepository';
import { EventType } from '@app-models';

const fakeEventTypes: EventType[] = [];

export class FakeEventTypeRepository extends FakeRepository<EventType> implements IEventTypeRepository {
  constructor() {
    super(fakeEventTypes);
  }
}
