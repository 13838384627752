import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService, ToasterService } from '@app-services';
import { TranslateService } from '@ngx-translate/core';
import { Paths, StorageKeys, ToasterTypes } from '@app-enums';

@Injectable({
  providedIn: 'root'
})
export class ShipmentSelectedGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const shipmentId = this.storageService.get(StorageKeys.SHIPMENT_ID);
    if (shipmentId) {
      return true;
    } else {
      this.toasterService.showMessage(
        this.translateService.instant('Shipment'), ToasterTypes.WARNING
      );
      this.router.navigateByUrl(Paths.HOME_TRIPS_AND_SHIPMENTS, { replaceUrl: true });
      return false;
    }
  }

}
