import { HttpClient } from '@angular/common/http';
import { DataService } from './DataService';
import { ApiClient } from '@libs/shared/data-access';
import { IMobileAppEnvironment } from '@app-environments/IMobileAppEnvironment';
import { ShipmentRepository } from './repositories/shipments/ShipmentRepository';
import { AssetRepository } from './repositories/assets/AssetRepository';
import { EventRepository } from './repositories/events/EventRepository';
import { PhonebookEntryRepository } from './repositories/phonebook-entries/PhonebookEntryRepository';
import { ChatMessageRepository } from './repositories/chats/ChatMessageRepository';
import { FileRepository } from './repositories/files/FileRepository';
import { EventTypeRepository } from './repositories/event-types/EventTypeRepository';
import {UserRepository} from './repositories/user/UserRepository';

export class WebApiDataService extends DataService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly environment: IMobileAppEnvironment,
  ) {
    super();

    const apiClient = new ApiClient(environment.api.baseUrl, httpClient);
    const mobileApiClient = new ApiClient(environment.api.baseUrlMobile, httpClient);

    this.users = new UserRepository(mobileApiClient);
    this.shipments = new ShipmentRepository(mobileApiClient);
    this.events = new EventRepository(mobileApiClient);
    this.eventTypes = new EventTypeRepository(mobileApiClient);
    this.chatMessages = new ChatMessageRepository(mobileApiClient);
    this.files = new FileRepository(mobileApiClient);

    this.assets = new AssetRepository(apiClient);
    this.phonebookEntries = new PhonebookEntryRepository(apiClient);
  }
}
