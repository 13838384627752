import { TripDateTime, TripStopCoordinates, TripStopTask } from '@app-models';
import { LegacyTripStopStatus } from '@app-enums';
import { EntityId } from '@libs/shared/types';

export interface TripStop {
  // taken from response
  bcrFileUrl?: string;
  city?: string;
  coordinates?: TripStopCoordinates;
  country?: string;
  distancePercentage?: number;
  kmToNextStop?: number;
  metersToNextStop?: number;
  minutesToNextStop?: number;
  polylineFileUrl?: string;
  postalCode?: string;
  referenceID?: EntityId;
  scheduledDateTime?: TripDateTime;
  secondsToNextStop?: number;
  stopID?: EntityId;
  street?: string;
  streetNumber?: string;
  tasks?: TripStopTask[];
  finished?: boolean;
  skipped?: boolean;
  arrived?: boolean;
  failed?: boolean;
  // additional
  arrivedDatetime?: Date;
  status: LegacyTripStopStatus;
  finishedDatetime: Date;
}
