import { Pipe, PipeTransform } from '@angular/core';
import { TripStop } from '@app-models';

@Pipe({
  name: 'tripStopIcon'
})
export class TripStopIconPipe implements PipeTransform {

  transform(stop: any, ...args: unknown[]): string {
    if (stop.finished && stop.skipped) {
      return 'fa-question-circle';
    }
    if (stop.finished && stop.failed) {
      return 'fa-times-circle';
    }
    if (stop.arrived && stop.finished) {
      return 'fa-check-circle';
    }
    if (stop.arrived && !stop.finished) {
      return 'fa-spinner';
    }
    return 'fa-circle-notch';
  }
}
