import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToasterTypes } from '@app-enums';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    public snackBar: MatSnackBar
  ) { }

  /**
   * Display notifications on the screen bottom.
   * The first parameter is message to be displayed.
   * The second one is OPTIONAL, and it's mode - success, warn, or error.
   * Default mode is SUCCESS.
   * The third one is also optional, and it's duration.
   * Default duration is 5 seconds.
   *
   * @param message string
   * @param mode NotificationTypes
   * @param duration number
   *
   * @returns void
   */
  showMessage(message: string, mode: ToasterTypes = ToasterTypes.SUCCESS, duration: number = 5000): void {
    const config = {
      duration,
      panelClass: mode + '-notification'
    } as MatSnackBarConfig;
    this.snackBar.open(message, 'Close', config);
  }
}
