import {Camera, CameraResultType, CameraSource, ImageOptions, Photo} from '@capacitor/camera';
import {Injectable} from '@angular/core';
import {ChatPhoto} from '@app-models';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  public chatPhoto: ChatPhoto;
  public allChatPhotos: ChatPhoto[];

  /**
   * Take a photo from phone's camera, using custom camera options.
   *
   * @returns Promise<any>
   */
  public async takePhoto(cameraSource: string = CameraSource.Camera): Promise<Photo> {
    const options = {
      quality: 80,
      height: 1500,
      correctOrientation: true,
      saveToGallery: false,
      resultType: CameraResultType.DataUrl,
      source: cameraSource,
    } as ImageOptions;
    return await Camera.getPhoto(options);
  }

}
