import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CreatePdfModes,
  Paths,
  LegacyShipmentStopTaskStatuses,
  StorageKeys,
  ToasterTypes,
  ShipmentStopTaskType
} from '@app-enums';
import {DataService, ShipmentService, StorageService, ToasterService} from '@app-services';
import {LegacyShipmentStopTask, LegacyShipmentStopTaskUI, TaskStatusChangeParams} from '@app-models';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { TaskStatusPopupComponent } from '../../popups/task-status-popup/task-status-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-stop-tasks',
  templateUrl: './shipment-stop-tasks.component.html',
  styleUrls: ['./shipment-stop-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentStopTasksComponent implements OnInit {

  public tasks$ = new BehaviorSubject<LegacyShipmentStopTaskUI[]>(null);
  public stopName$ = new BehaviorSubject<string>(null);
  public statusesEnum = LegacyShipmentStopTaskStatuses;
  private allTasks: LegacyShipmentStopTaskUI[];

  constructor(
    private router: Router,
    private shipmentService: ShipmentService,
    private storageService: StorageService,
    private dataService: DataService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getTasks();
  }

  //#region  UI Responses

  /**
   * Get back to list of shipment stops.
   *
   * @returns void
   */
  public onGoBack(): void {
    this.storageService.remove(StorageKeys.SHIPMENT_STOP_ID);
    this.storageService.remove(StorageKeys.SHIPMENT_STOP_NAME);
    this.router.navigateByUrl(Paths.HOME_SHIPMENT_STOPS, { replaceUrl: true });
  }

  /**
   * Scan document that will be assigned to the task's shipment.
   *
   * @returns void
   */
  public onScanDocument(task: LegacyShipmentStopTask): void {
    this.storageService.set(StorageKeys.TASK_ID, task.taskId);
    const queryParams = task.documentType ? `?documentType=${task.documentType}` : '';
    this.router.navigateByUrl(`${Paths.HOME_CREATE_PDF}${queryParams}`, {
      replaceUrl: true,
      state: {
        mode: CreatePdfModes.SHIPMENT,
        previousRoute: this.router.url
      }
    });
  }

  /**
   * Get task consignments.
   *
   * @param task ShipmentStopTask
   *
   * @returns void
   */
  public onGetConsignmentDetails(task: LegacyShipmentStopTask): void {
    this.allTasks[this.allTasks.indexOf(task)].expanded = !this.allTasks[this.allTasks.indexOf(task)].expanded;
    this.tasks$.next(this.allTasks);
  }

  /**
   * This method will change the status for the task by sending a http request.
   *
   * @param task Shipment Stop Task
   *
   * @returns void
   */
  public onOpenChangeStatusPopup(task: LegacyShipmentStopTask): void {
    const dialogRef = this.dialog.open(TaskStatusPopupComponent, {minWidth: 300});
    dialogRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((status) => {
        this.handleStatusChange(task, status);
      });
  }

  protected getDocTypeString(docType: string): string {
    const str = `PDF.DocumentType_${docType}`;
    const translation = this.translateService.instant(str);
    if(translation === str) {
      return docType;
    }
    return translation;
  }

  //#endregion

  //#region Methods

  /**
   * Get shipment in order to read tasks.
   *
   * @returns void
   */
  private getTasks(): void {
    const shipmentId = this.storageService.get(StorageKeys.SHIPMENT_ID);
    const stopId = this.storageService.get(StorageKeys.SHIPMENT_STOP_ID);
    const stopName = this.storageService.get(StorageKeys.SHIPMENT_STOP_NAME);
    this.stopName$.next(stopName);
    this.shipmentService.getTasksForStop(shipmentId, stopId).subscribe(this.handleTasks);
    this.shipmentService.onShipmentUpdate(shipmentId, this.handleTasks);
  }

  //#endregion

  //#region Handlers

  /**
   * Update UI with the list of tasks, if any.
   *
   * @param tasks Task[]
   *
   * @returns void
   */
  private handleTasks = (tasks: LegacyShipmentStopTask[]): void => {
    this.allTasks = tasks;
    this.tasks$.next(this.allTasks);
  };

  /**
   * This method will change the status for the task
   * by sending a http request.
   *
   * @param task any Task whose status is going to be changed
   *
   * @param status new status for the task
   *
   * @returns void
   */
  private handleStatusChange(task: LegacyShipmentStopTask, status: LegacyShipmentStopTaskStatuses): void {
    if (!task || task.status === status) {
      return;
    }
    const shipmentId = this.storageService.get(StorageKeys.SHIPMENT_ID);
    const params = {
      status,
    } as TaskStatusChangeParams;

    this.dataService.shipments
      .changeShipmentTaskStatus(shipmentId, this.storageService.get(StorageKeys.SHIPMENT_STOP_ID), task.taskId, params)
      .pipe(first())
      .subscribe(
        () => {
          this.allTasks[this.allTasks.indexOf(task)].status = status;
          this.tasks$.next(this.allTasks);
          this.toasterService.showMessage(this.translateService.instant('Shipments.TaskStatusChanged'), ToasterTypes.SUCCESS);
        },
        () => {
          this.toasterService.showMessage(this.translateService.instant('General.AnErrorOcurred'), ToasterTypes.ERROR);
        },
      );
  }

  //#endregion
  protected readonly ShipmentStopTaskType = ShipmentStopTaskType;
}
