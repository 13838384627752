import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService, ToasterService } from '@app-services';
import { Paths, StorageKeys, ToasterTypes } from '@app-enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AssetSelectedGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const truckId = this.storageService.get(StorageKeys.TRUCK_ID);
    if (truckId) {
      return true;
    } else {
      this.toasterService.showMessage(
        this.translateService.instant('Chat.FirstChooseTheTruck'),
        ToasterTypes.WARNING
      );
      this.router.navigateByUrl(Paths.HOME_SELECT_ASSET);
      return false;
    }
  }

}
