import { Asset } from '@app-models';
import { FakeRepository } from '@libs/shared/data-access';
import { IAssetRepository } from './IAssetRepository';
import { EntityId } from '@libs/shared/types';
import { Observable, of } from 'rxjs';

const fakeAssets: Asset[] = [];

export class FakeAssetRepository extends FakeRepository<Asset> implements IAssetRepository {
  constructor() {
    super(fakeAssets);
  }

  public assignDriverToAsset(assetId: EntityId, pushToken?: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }
}
