// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.radio-button {
  margin: 15px 0;
}

.radio-button-text {
  font-family: "Bebas Neue";
  font-size: 15px;
}

.button-left {
  margin-right: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhc2stc3RhdHVzLXBvcHVwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsY0FBQTtBQUNGOztBQUVBO0VBQ0UsY0FBQTtBQUNGOztBQUVBO0VBQ0UseUJBQUE7RUFDQSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtBQUNGIiwiZmlsZSI6InRhc2stc3RhdHVzLXBvcHVwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnJhZGlvLWdyb3VwIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgbWFyZ2luOiAxNXB4IDA7XG59XG5cbi5yYWRpby1idXR0b24ge1xuICBtYXJnaW46IDE1cHggMDtcbn1cblxuLnJhZGlvLWJ1dHRvbi10ZXh0IHtcbiAgZm9udC1mYW1pbHk6IFwiQmViYXMgTmV1ZVwiO1xuICBmb250LXNpemU6IDE1cHg7XG59XG5cbi5idXR0b24tbGVmdCB7XG4gIG1hcmdpbi1yaWdodDogYXV0bztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/web/src/app/modules/home/popups/task-status-popup/task-status-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACA,guBAAguB","sourcesContent":[".radio-group {\n  display: flex;\n  flex-direction: column;\n  margin: 15px 0;\n}\n\n.radio-button {\n  margin: 15px 0;\n}\n\n.radio-button-text {\n  font-family: \"Bebas Neue\";\n  font-size: 15px;\n}\n\n.button-left {\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
