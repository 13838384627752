import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Asset } from '@app-models';
import { SelectedTruckService, StorageService } from '@app-services';
import { MatDialog } from '@angular/material/dialog';
import { TrailerManagementComponent } from '../../popups/trailer-management/trailer-management.component';
import { Paths, StorageKeys } from '@app-enums';

@Component({
  selector: 'app-chat-truck-and-trailer-info',
  templateUrl: './chat-truck-and-trailer-info.component.html',
  styleUrls: ['./chat-truck-and-trailer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTruckAndTrailerInfoComponent implements OnInit {

  public truck$ = new BehaviorSubject<string>(null);
  public trailer$ = new BehaviorSubject<Asset>(null);

  constructor(
    private router: Router,
    private selectedTruckService: SelectedTruckService,
    private storageService: StorageService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadSavedState();
    this.init();
  }

  //#region UI Responses

  /**
   * Navigate to home page in order to select the truck.
   *
   * @returns void
   */
  public onGoToTruck(): void {
    this.router.navigateByUrl(Paths.SELECT_ASSET, { replaceUrl: true, state: { automaticSelect: false } });
  }

  /**
   * Choose a trailer for the current truck.
   *
   * @returns void
   */
  public onGoToTrailer(): void {
    this.dialog.open(TrailerManagementComponent);
  }

  //#endregion

  //#region Methods

  /**
   * Load saved information about truck, in case of page reload.
   *
   * @returns void
   */
  private loadSavedState(): void {
    const displayName = this.storageService.get(StorageKeys.TRUCK_DISPLAY_NAME);
    if (displayName) {
      this.selectedTruckService.licencePlate = displayName;
    }
    const truckId = this.storageService.get(StorageKeys.TRUCK_ID);
    if (truckId) {
      this.selectedTruckService.truckId = truckId;
    }
  }

  /**
   * Update UI with info about truck, and subscribe to trailer changes.
   *
   * @returns void
   */
  private init() {
    this.truck$.next(this.selectedTruckService.licencePlate);
    this.subscribeToTruckChanges();
  }

  //#endregion

  //#region Subscriptions

  /**
   * Listen for the Truck changes and handle it
   * by listening for it's Trailer changes.
   *
   * @returns void
   */
  private subscribeToTruckChanges(): void {
    this.selectedTruckService
      .getAssetDetailsById(this.selectedTruckService.truckId)
      .subscribe((truckDetails) => {
        this.handleTruckDetails(truckDetails);
      });
  }

  /**
   * Listen for the Trailer changes and handle it.
   *
   * @param trailerId string
   *
   * @returns void
   */
  private subscribeToTrailer(trailerId: string): void {
    this.selectedTruckService.
      getAssetDetailsById(trailerId)
      .subscribe((trailer) => {
        this.handleTrailerDetails(trailer);
      });
  }

  //#endregion

  //#region Handlers

  /**
   * Handle Truck changes by listening for it's Trailer details.
   *
   * @param trailerDetails Asset
   *
   * @returns void
   */
  private handleTruckDetails(trailerDetails: Asset): void {
    this.subscribeToTrailer(trailerDetails.assetIDAfter);
  }

  /**
   * Handle Trailer changes by updating UI-related behavior subject property.
   *
   * @param trailer Asset
   *
   * @returns void
   */
  private handleTrailerDetails(trailer: Asset): void {
    this.trailer$.next(trailer);
  }

  //#endregion

}
