import {ChatMessageTypes} from "@app-enums";

export interface ChatMessage {
  datetime: number;
  createdAt: string;       //ISO Date string
  type: ChatMessageTypes;
  text: string;
  imageUrl?: string;       // Deprecated
  imageId?: number;        // Deprecated
  imageType?: string;      // Deprecated
  fileId?: number;
  fileType?: string;       // 'image/png' etc
  senderName?: string;
  senderId?: string;
  readAt?: string;         // ISO Date
  read?: boolean;
  image?: string;
  translation?: string;
  fileData?: Blob;
}

export interface ChatMessageUI extends ChatMessage {
  showOriginal?: boolean;
}
