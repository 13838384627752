import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'shipmentStopDate'
})
export class ShipmentStopDatePipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {

    if (isNaN(+value) === false) {
      return format(new Date(+value * 1000), 'dd.MM.yyyy HH:mm');
    } else {
      const data = value.toString().split(' - ');
      const suffix = data[1].substring(0, data[1].length - 3);
      return `${data[0]} ${suffix}`;
    }
  }

}
