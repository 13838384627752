import { Jitsi } from 'capacitor-jitsi-meet';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth';
import { MatDialog } from '@angular/material/dialog';
import { PlatformService } from './platform.service';
import {VideoCallComponent} from "../../../modules/home/components/video-call/video-call.component";

@Injectable({
  providedIn: 'root'
})
export class JitsiCallService {

  private readonly jitsiBridge = "https://jitsi.d3eif.com";
  private readonly jitsiScript = 'https://jitsi.d3eif.com/external_api.js';

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private platformService: PlatformService,
  ) { }

  /**
   * Start Initializing Jitsi call, depending on the device/platform
   *
   * @param url string
   *
   * @returns void
   */
  public async init(url: string): Promise<void> {
    const user = await this.authService.userData;
    const urlParts = url.split('/');
    const roomName = urlParts[urlParts.length - 1];
    if (!this.platformService.isMobileApp()) {
      this.dialog.open(VideoCallComponent, { data: { user, roomName: roomName }, width: '100%', height: '100%', maxWidth: '100%' });
    } else {
      const result = await Jitsi.joinConference({
        // required parameters
        roomName: roomName, // room identifier for the conference
        url: this.jitsiBridge, // endpoint of the Jitsi Meet video bridge

        // recommended settings for production build. see full list of featureFlags in the official Jitsi Meet SDK documentation
        featureFlags: {
          'prejoinpage.enabled': false, // go straight to the meeting and do not show the pre-join page
          'recording.enabled': false, // disable as it requires Dropbox integration
          'live-streaming.enabled': false, // 'sign in on Google' button not yet functional
          'android.screensharing.enabled': false, // experimental feature, not fully production ready
        },

        // optional parameters
        displayName: user.displayName, // user's display name
        email: user.email, // user's email
        avatarURL: user.photoURL ?? '', // user's avatar url
        startWithAudioMuted: false, // start with audio muted, default: false
        startWithVideoMuted: true, // start with video muted, default: false
        chatEnabled: true, // enable Chat feature, default: true
        inviteEnabled: false, // enable Invitation feature, default: true
        recordingEnabled: true,
      });
    }
  }

  /**
   * Fetch Jitsi script
   *
   * @returns Promise<void>
   */
  loadScript(): Promise<void> {
    if (this.platformService.isMobileApp()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = this.jitsiScript;
      scriptElement.onload = () => resolve();
      scriptElement.onerror = () => reject();

      const headElement = document.getElementsByTagName('head')[0];
      headElement.appendChild(scriptElement);
    });
  }

}
