import { Injectable } from '@angular/core';
import { DataService, PushService } from '@app-services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Asset } from '@app-models';
import { EntityId } from '@libs/shared/types';
import { of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetSelectService {

  constructor(
    private readonly pushService: PushService,
    private readonly dataService: DataService,
  ) {}

  /**
   * After User has selected the truck, the login to asset will be done.
   * Login to asset is method name inherited from legacy system.
   * Basically login to asset means that User has selected the truck, and
   * the rest of the app will be based on that info, including the chat content,
   * trailer info, licence plate info etc.
   *
   * @param assetId string
   *
   * @returns void
   */
  public loginToAsset(assetId: EntityId) {
    return this.pushService.getToken()
      .pipe(
        catchError(() => of('')),
        switchMap(pushToken => {
          return this.dataService.assets.assignDriverToAsset(assetId, pushToken);
        })
      );
  }

  /**
   * Initializes and return Asset select form.
   *
   * @returns FormGroup
   */
  public getAssetSelectForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      asset: new UntypedFormControl(null, Validators.required),
      assetName: new UntypedFormControl(null),
    });
  }

  /**
   * This method set asset's displayName, based on existing
   * values of displayName and suggestedName.
   *
   * @param assetsList Asset[]
   *
   * @returns void
   */
  public mapAssetsDisplayName(assetsList: Asset[]): void {
    assetsList.forEach((asset: Asset) => {
      if (asset.suggestedName) {
        asset.displayName = asset.suggestedName;
      }
      if (asset.displayName) {
        asset.displayName = asset.displayName;
      }
    });
  }

  /**
   * Sort assets by DisplayName in alphabetical order.
   *
   * @param assets Asset[]
   *
   * @returns void
   */
  public sortAssetsByDisplayName(assets: Asset[]): void {
    assets.sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 0;
    });
  }
}
