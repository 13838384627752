import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private platform: Platform,
    private dialog: MatDialogRef<boolean>
  ) {

  }

  ngOnInit(): void {
    this.platform.backButton.pipe(first()).subscribe(value => {
      this.dialog.close(true);
    });
  }

}
