import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-trips-and-shipments',
  templateUrl: './trips-and-shipments.component.html',
  styleUrls: ['./trips-and-shipments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripsAndShipmentsComponent {

  constructor() { }

}
