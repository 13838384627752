import { IShipmentRepository } from './repositories/shipments/IShipmentRepository';
import { IAssetRepository } from './repositories/assets/IAssetRepository';
import { IEventRepository } from './repositories/events/IEventRepository';
import { IPhonebookEntryRepository } from './repositories/phonebook-entries/IPhonebookEntryRepository';
import { IChatMessageRepository } from './repositories/chats/IChatMessageRepository';
import { IFileRepository } from './repositories/files/IFileRepository';
import { IEventTypeRepository } from './repositories/event-types/IEventTypeRepository';
import { BaseDataService } from '@libs/shared/data-access';

export abstract class DataService extends BaseDataService {
  public shipments!: IShipmentRepository;
  public assets!: IAssetRepository;
  public events!: IEventRepository;
  public eventTypes!: IEventTypeRepository;
  public chatMessages!: IChatMessageRepository;
  public files!: IFileRepository;
  public phonebookEntries!: IPhonebookEntryRepository;
}
