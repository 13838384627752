export enum LegacyTripStopStatus {
  STARTED = 'started',
  IN_PROGRESS = 'in progress',
  SKIPPED = 'skipped',
  DONE = 'done',
  FINISHED = 'finished',
  FAILED = 'failed'
}

export enum ShipmentStopStatus {
  UPCOMING = 'upcoming', ARRIVED = 'arrived', LEFT = 'left'
}
