import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
    private platform: Platform
  ) { }

  /**
   * Check if platform is mobile phone Ionic app
   *
   * @returns boolean
   */
  public isMobileApp(): boolean {
    if (!this.platform.is('pwa') && this.platform.is('android') && this.platform.is('capacitor')) {
      return true;
    }
    return false;

  }
}
