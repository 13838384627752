import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatMessage } from '@app-models';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  /**
   * Get message that contains Blob image and convert it to base64
   *
   * @param imageMessage ChatMessage
   * @param response Blob
   *
   * @returns ChatMessage
   */
  public async convertBlobToBase64(imageMessage: ChatMessage, response: Blob): Promise<ChatMessage> {
    if (!response) {
      return null;
    }
    let data = new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(response);
    });
    await data.then(
      (base64: string) => {
        imageMessage.image = base64.substring('data:image/png;base64,'.length, base64.length);
      }
    );
    return imageMessage;
  }

  /**
   * Convert base64 or Image to File
   *
   * @param imageOrPdf image or pdf (base64)
   * @param type :string
   *
   * @returns File
   */
  public async convertToFile(imageOrPdf: string, type: string, fileName?: string): Promise<File> {
    const res: Response = await fetch(imageOrPdf);
    const blob: Blob = await res.blob();
    return new File([blob], fileName ?? (new Date()).getTime().toString(), { type: type });
  }
}
