import { TripDateTime, TripStop } from '@app-models';
import { EntityId } from '@libs/shared/types';

export interface Trip {
  assetID: EntityId;
  companyID: EntityId;
  finished: boolean;
  licencePlate: string;
  permissions: any;
  polylineURL: string;
  readPermissions: string[];
  startDatetime: TripDateTime;
  status: string;
  stopsabcxyzasdf: { [property: string]: TripStop };
  temperatureControlled: boolean;
  totalDistance: number;
  totalDistanceMeters: number;
  totalDrivingTime: number;
  totalDrivingTimeSeconds: number;
  tripID: EntityId;
  vehicleProfile: any;
}
