import { ChatMessage, NewChatMessageDto } from '@app-models';
import { FakeRepository, Pagination } from '@libs/shared/data-access';
import { IChatMessageRepository } from './IChatMessageRepository';
import { Observable } from 'rxjs';
import {EntityId, TranslationKey} from '@libs/shared/types';

const fakeChatMessages: ChatMessage[] = [];

export class FakeChatMessageRepository extends FakeRepository<ChatMessage> implements IChatMessageRepository {
  constructor() {
    super(fakeChatMessages);
  }

  public add(truckId: EntityId, message: NewChatMessageDto): Observable<ChatMessage> {
    return undefined;
  }

  public getMessages(id: EntityId, language: TranslationKey, pagination: Pagination, loader: boolean): Observable<ChatMessage[]> {
    return this.getAll(undefined, undefined, pagination);
  }
}
