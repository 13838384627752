// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.arrived {
  color: var(--color-warning-500) !important;
}

.left {
  color: #04b899 !important;
}

.timestamp-span {
  align-self: end;
  padding-right: 8px;
  margin: 4px 0;
  color: #e11a47;
  width: 100%;
  border-bottom: 1px solid;
  text-align: end;
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNoaXBtZW50LWNhcmQtdGltZXN0YW1wcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQUE7QUFDRjs7QUFFQTtFQUNFLDBDQUFBO0FBQ0Y7O0FBRUE7RUFDRSx5QkFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLGtCQUFBO0VBQ0EsYUFBQTtFQUNBLGNBQUE7RUFDQSxXQUFBO0VBQ0Esd0JBQUE7RUFDQSxlQUFBO0VBQ0EsY0FBQTtBQUNGIiwiZmlsZSI6InNoaXBtZW50LWNhcmQtdGltZXN0YW1wcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgd2lkdGg6IDEwMCU7XG59XG5cbi5hcnJpdmVkIHtcbiAgY29sb3I6IHZhcigtLWNvbG9yLXdhcm5pbmctNTAwKSAhaW1wb3J0YW50O1xufVxuXG4ubGVmdCB7XG4gIGNvbG9yOiAjMDRiODk5ICFpbXBvcnRhbnQ7XG59XG5cbi50aW1lc3RhbXAtc3BhbiB7XG4gIGFsaWduLXNlbGY6IGVuZDtcbiAgcGFkZGluZy1yaWdodDogOHB4O1xuICBtYXJnaW46IDRweCAwO1xuICBjb2xvcjogI2UxMWE0NztcbiAgd2lkdGg6IDEwMCU7XG4gIGJvcmRlci1ib3R0b206IDFweCBzb2xpZDtcbiAgdGV4dC1hbGlnbjogZW5kO1xuICBkaXNwbGF5OiBibG9jaztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/web/src/app/modules/home/components/shipment-card-timestamps/shipment-card-timestamps.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,0CAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,wBAAA;EACA,eAAA;EACA,cAAA;AACF;AACA,g5BAAg5B","sourcesContent":[":host {\n  width: 100%;\n}\n\n.arrived {\n  color: var(--color-warning-500) !important;\n}\n\n.left {\n  color: #04b899 !important;\n}\n\n.timestamp-span {\n  align-self: end;\n  padding-right: 8px;\n  margin: 4px 0;\n  color: #e11a47;\n  width: 100%;\n  border-bottom: 1px solid;\n  text-align: end;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
