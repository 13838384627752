import {EnvironmentStage, SemanticVersionString} from '@libs/shared/environment';
import packageJson from '../../../../package.json';
import { IMobileAppEnvironment } from '@app-environments/IMobileAppEnvironment';

export const environment: IMobileAppEnvironment = {
  stage: EnvironmentStage.TEST,
  version: packageJson.version as SemanticVersionString,
  api: {
    baseUrl: 'https://api.qa.drip-log.com/',
    baseUrlMobile: 'https://api.qa.drip-log.com/d4d/',
  },
  firebase: {
    appId: '1:801217274195:android:d154c3f444db17c445c1a6',
    locationId: 'us-central',
    apiKey: 'AIzaSyBhcpLPNILOUDLulIbzCJOZfFY3RsAfjyg',
    authDomain: 'dashboard-test-ba888.firebaseapp.com',
    databaseURL: 'https://dashboard-test-ba888.firebaseio.com',
    projectId: 'dashboard-test-ba888',
    storageBucket: 'dashboard-test-ba888.appspot.com',
    messagingSenderId: '801217274195',
  },
  i18n: {
    availableLanguages: [
      { name: 'English', code: 'en' },
      { name: 'Deutsch', code: 'de' },
      { name: 'Polski', code: 'pl' },
      { name: 'български', code: 'bg' },
      { name: 'Română', code: 'ro' },
      { name: 'Hrvatski', code: 'hr' },
      { name: 'Slovenský', code: 'sk' },
      { name: 'Český', code: 'cs' },
      { name: 'Русский', code: 'ru' },
      { name: 'Magyar', code: 'hu' },
    ],
    defaultLanguageCode: 'en',
  },
} as const;
