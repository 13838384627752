import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderConsumer } from '@app-models';
import { LoaderConsumersKeys } from '@app-enums';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public showLoader$ = new BehaviorSubject<boolean>(false);
  private loaderConsumers: LoaderConsumer[];

  constructor() {
    this.loaderConsumers = [];
  }

  public showLoader(loaderKey: LoaderConsumersKeys): void {
    const consumer = this.loaderConsumers.find(x => x.loaderKey === loaderKey);
    if (consumer) {
      consumer.callsCounter++;
    } else {
      this.loaderConsumers.push({ loaderKey, callsCounter: 0 } as LoaderConsumer);
    }
    this.showLoader$.next(true);
  }

  public hideLoader(loaderKey: LoaderConsumersKeys): void {
    const consumer = this.loaderConsumers.find(x => x.loaderKey === loaderKey);
    if (consumer && consumer.callsCounter > 0) {
      consumer.callsCounter--;
    }
    if (!this.loaderConsumers.find(x => x.callsCounter > 0)) {
      this.showLoader$.next(false);
    }
  }
}
