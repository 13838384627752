import { AdvancedHttpClient, Filter, Pagination, Sort, WebDataRepository } from '@libs/shared/data-access';
import { ChatMessage, NewChatMessageDto } from '@app-models';
import { IChatMessageRepository } from './IChatMessageRepository';
import { Observable } from 'rxjs';
import {EntityId, TranslationKey} from '@libs/shared/types';

export class ChatMessageRepository extends WebDataRepository<ChatMessage> implements IChatMessageRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'users/chats');
  }

  public override getAll(filters?: Filter[], sort?: Sort<ChatMessage>[], pagination?: Pagination): Observable<ChatMessage[]> {
    throw new Error('Method not supported.');
  }

  public override getById(id: EntityId): Observable<ChatMessage> {
    throw new Error('Method not supported.');
  }

  public add(truckId: EntityId, message: NewChatMessageDto): Observable<ChatMessage> {
    const formData = new FormData();
    if (message.file) {
      formData.append('file', message.file, message.fileName);
    }
    if (message.text) {
      formData.append('text', message.text);
    }
    if (message.imageType) {
      formData.append('imageType', message.imageType);
    }
    if(message.language) {
      formData.append('language', message.language);
    }

    return this.httpClient.post({
      url: `${this.resource}/${truckId}`,
      queryParams: {
        referenceType: 'asset'
      },
      body: formData });
  }

  public getMessages(id: EntityId, language: TranslationKey, pagination: Pagination, loader: boolean): Observable<ChatMessage[]> {
    return this.httpClient.get({
      url: `${this.resource}/${id}`,
      queryParams: {
        offset: pagination.pageNumber,
        limit: pagination.pageSize,
        language,
        referenceType: 'asset'
      },
      headers: { loader: loader.toString() },
    });
  }
}
