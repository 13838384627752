import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { LegacyShipment, Shipment } from '@app-models';
import { DataService } from '@app-services';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  public shipments$ = new BehaviorSubject<LegacyShipment[]>([]);

  constructor(
    private dataService: DataService,
    private socket: Socket,
  ) {}

  /**
   * Get all shipments from AFS.
   *
   * @returns Observable<any>
   */
  public getShipments(truckId: string): Observable<LegacyShipment[]> {
    return this.dataService.shipments.getShipmentsByTruckId(truckId);
  }

  /**
   * returns true if the asset currently has shipments
   * @param truckId
   */
  public hasShipments(truckId: string): Observable<boolean> {
    return this.dataService.shipments.hasShipments(truckId);
  }

  /**
   * Get Shipment details from AFS.
   *
   * @param shipmentId string
   *
   * @returns Observable<any>
   */
  public getShipmentById(shipmentId: string): Observable<Shipment> {
    return this.dataService.shipments.getById(shipmentId);
  }

  /**
   * get all tasks for a specific stop
   * @param shipmentId
   * @param stopId
   */
  public getTasksForStop(shipmentId: string, stopId: string) {
    return this.dataService.shipments.getShipmentTasks(shipmentId, stopId);
  }

  public onShipmentUpdate(shipmentId: string, callback: Function) {
    // Socket not working anymore
    //this.socket.on(`shipment_${shipmentId}`, callback);
  }
}
