import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {
  EventFormFileComponent,
  EventFormSelectComponent, EventFormSignatureComponent,
  EventFormTextComponent,
  EventFormTextfieldComponent
} from '../../components/event-forms';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {CreateEventField, EventType, EventTypeFormField} from '@app-models';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '../../../../../../../../libs/drip-shared-components/src';
import {FormBuilderService, PlatformService, ToasterService} from '@app-services';
import {ToasterTypes} from '@app-enums';

@Component({
  selector: 'app-event-form-page',
  standalone: true,
  imports: [CommonModule, MatIconButton, MatIcon, ReactiveFormsModule, EventFormTextComponent, EventFormTextfieldComponent, EventFormSelectComponent, EventFormSignatureComponent, EventFormFileComponent, MatSlideToggle, MatButton, TranslateModule],
  templateUrl: './event-form-page.component.html',
  styleUrl: './event-form-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormPageComponent implements OnInit {
  protected selectedEventType$ = new BehaviorSubject<EventType>(null);
  protected fields: EventTypeFormField[];
  protected eventForm$ = new BehaviorSubject<FormGroup | null>(null);
  protected readonly appUrl = 'https://play.google.com/store/apps/details?id=com.d3eif.d4d ';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilderService: FormBuilderService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly platformService: PlatformService,
  ) {
    this.route.paramMap.subscribe(params => {
      const eventId = params.get('eventId');
      if(eventId) {
        this.setupEvent(eventId);
      }
    });
  }

  ngOnInit() {
    this.fields = this.formBuilderService.defaultFormFields();
  }

  protected getControl(key: string): FormControl {
    return this.eventForm$.value.get(key) as FormControl;
  }

  /**
   * Create dynamic form from loaded fields.
   *
   * @returns void
   */
  private createDynamicForm(): void {
    const eventForm = this.formBuilderService.createEventDynamicForm(this.fields);
    this.eventForm$.next(eventForm);
  }

  private setupEvent(eventId: string) {
    this.formBuilderService.getEventTypeById(eventId).subscribe((value) => {
      this.fields = value.fields.map((field) => field);
      this.formBuilderService.defaultFormFields().map((field) => this.fields.unshift(Utils.clone(field)));

      this.createDynamicForm();
      this.selectedEventType$.next(value);
    });
  }

  protected onAddNewEvent(): void {
    const currentForm = this.eventForm$.value;
    if (!currentForm || currentForm.invalid) {
      this.toasterService.showMessage(this.translateService.instant('eventHandler.requiredMissing'), ToasterTypes.ERROR);
      return;
    }

    let finalFields: CreateEventField[] = [];

    this.fields.forEach((field) => {
      if (field.id) {
        finalFields.push({
          eventFormFieldId: field.id,
          content: this.eventForm$.value.get(field.name).value,
        });
      }
    });
    this.formBuilderService
      .addNewEvent({
        eventTypeId: this.selectedEventType$.getValue().id,
        eventFormContents: finalFields,
        client: this.eventForm$.value.value.client,
        reference: this.eventForm$.value.value.reference,
        visibleForCustomer: this.eventForm$.value.value.visibleForCustomer,
      })
      .subscribe(() => {
        this.onPreviousPage();
        this.eventForm$.value.reset();
        this.toasterService.showMessage(this.translateService.instant('eventHandler.successCreate'), ToasterTypes.SUCCESS);
      });
  }

  /**
   * Cancel the form builder, go step back
   */
  protected onPreviousPage(): void {
    this.fields = this.formBuilderService.defaultFormFields(); // set default form
    this.selectedEventType$.next(null);
    window.history.back();
  }

  /**
   * Cancel the form builder, go step back
   */
  protected onCancel(): void {
    this.onPreviousPage();
  }

  protected onOpenApp(): void {
    if (this.platformService.isMobileApp()) {
      window.open(this.appUrl, '_system', 'location=yes');
    } else {
      window.location.reload();
    }
  }
}
