import { LegacyCoordinates, LegacyShipmentAddress, LegacyShipmentStopTask, ShipmentStopTask } from '@app-models';
import { ShipmentStopStatus } from '@app-enums';
import { EntityId } from '@libs/shared/types';

export interface LegacyShipmentStopTimeWindow {
  start: number;
  end: number;
}

export interface LegacyShipmentStopETA {
  etaUnixSeconds: number;
  lastCalculationUnixSeconds: number;
  remainingDistanceMeters: number;
}

export interface LegacyShipmentStop {
  stopId: EntityId;
  name?: string;
  status: string;
  address?: LegacyShipmentAddress;
  coordinates?: LegacyCoordinates;
  arrivalTime?: string;
  leaveTime?: string;
  remainingDistance?: number;
  duration?: number;
  eta?: LegacyShipmentStopETA;
  $type?: string;
  tasks?: LegacyShipmentStopTask[];
  taskStatus: string;
  timeWindow: LegacyShipmentStopTimeWindow;
  timeWindowHistoric?: LegacyShipmentStopTimeWindow;
}

export interface ShipmentStop {
  address: {
    city?: string;
    country?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
  };
  consignmentIds: EntityId[];
  coordinates: { latitude: number; longitude: number };
  id: EntityId;
  name: string;
  arrivalTime: string;
  leaveTime: string;
  plannedArrivalTime: string;
  plannedLeaveTime: string;
  plannedRoute: {
    polylineURL: string;
    totalMeters: number;
    totalSeconds: number;
  };
  status?: ShipmentStopStatus;
  //  { status type is string or this
  //   ETA: number;        // unix timestamp?
  //   arrived: number     // unix timestamp?
  //   finished: number;   // unix timestamp?
  //   distancePercentage: number;
  //   distanceToGo: number;
  // };
  tasks: ShipmentStopTask[];
  window: {
    end: number;
    start: number;
  };
  assetsInfo: any;
}
