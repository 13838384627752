import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FormBuilderService} from '@app-services';
import {Paths} from '@app-enums';
import {EventType} from '@app-models';
import {Router} from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  protected eventTypesList$ = new BehaviorSubject<EventType[]>(null);
  protected displayedEventsList$ = new BehaviorSubject<EventType[]>(null);

  constructor(
    private readonly formBuilderService: FormBuilderService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.formBuilderService.getAllDripEventTypes().subscribe(this.handleEventTypes);
  }

  /**
   * Handle all event types
   *
   * @param eventTypes eventType[]
   */
  private handleEventTypes = (eventTypes: EventType[]): void => {
    this.eventTypesList$.next(eventTypes);
    this.displayedEventsList$.next(eventTypes);
  };

  /**
   * Select the form type and init the form
   *
   * @param selectedEventType eventType
   */
  protected onIconClick(selectedEventType: EventType): void {
    this.router.navigate([Paths.HOME_EVENT, selectedEventType.id]);
  }

  protected readonly FormControl = FormControl;
}
