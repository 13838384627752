import { Observable, of, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Filter, FilterOperator, Pagination, Sort, SortDirection } from '../../types';
import { IRepository } from './IRepository';
import { EntityId } from '../../../types';
import { BaseModel } from '../../../models';
import { Utils } from '../../../utils';

export class FakeRepository<T extends BaseModel> implements IRepository<T> {
  constructor(protected entities: T[]) {
  }

  public static generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public getAll(filters?: Filter[], sort?: Sort<T>[], pagination?: Pagination): Observable<T[]> {
    return this.delayResponse(this.getEntities()).pipe(
      map((items) => {
        if (items) {
          items = this.applyFilters(items, filters);

          items = this.applySorting(items, sort);
          items = this.applyPagination(items, pagination);
        }

        return items || [];
      })
    );
  }

  public getById(id: EntityId): Observable<T> {
    // const entity = this.entities.find((e) => e.id === id); // TODO
    const entity = this.entities[0];
    if (entity) {
      return this.delayResponse(of(Utils.clone(entity)!));
    }
    return this.throwElementNotFoundError(id);
  }

  protected getEntities(): Observable<T[] | undefined> {
    return of(Utils.clone(this.entities));
  }

  protected delayResponse<T>(observable: Observable<T>, maxDelayMs = 800): Observable<T> {
    return observable.pipe(delay(Math.random() * maxDelayMs));
  }

  protected applyFilter(items: T[], f: Filter): T[] {
    items = items.filter((item) => {
      // @ts-ignore
      const value = item[f.fieldName] as any;
      switch (f.operator) {
        case FilterOperator.Equals:
          return value == f.value;
        case FilterOperator.NotEquals:
          return value != f.value;
        case FilterOperator.Contains:
          return String(value).toLowerCase().includes(String(f.value).toLowerCase());
        case FilterOperator.GreaterThan:
          return value > f.value;
        case FilterOperator.LowerThan:
          return value < f.value;
        case FilterOperator.In: {
          const array = typeof f.value === 'string' ? JSON.parse(f.value) : f.value;
          if (Array.isArray(array)) {
            return array.includes(value);
          }
          return;
        }
      }
    });
    return items;
  }

  protected throwElementNotFoundError(id: EntityId) {
    return throwError(() => new Error(`No element with id ${id} was found!`));
  }

  private applyFilters(items: T[], filters?: Filter[]): T[] {
    if (filters?.length) {
      filters.forEach((filter) => {
        if (filter.fieldName === 'search') {
          const searchStr = String(filter.value).toLowerCase().trim();
          items = items.filter((item) =>
            Object.keys(item).some((key) => {
              // @ts-ignore
              const value = item[key];
              if (value) {
                return String(value).toLowerCase().includes(searchStr);
              }
              return false;
            })
          );
        } else {
          items = this.applyFilter(items, filter);
        }
      });
    }
    return items;
  }

  private applySorting<R>(items: R[], sort?: Sort<R>[]): R[] {
    if (sort) {
      items = this.sortBy(items, sort[0].fieldName as string, sort[0].direction == SortDirection.Ascending ? +1 : -1);
    }
    return items;
  }

  private applyPagination<R>(items: R[], pagination?: Pagination): R[] {
    if (pagination) {
      items.splice(0, pagination.pageNumber * pagination.pageSize);
      items.splice(pagination.pageSize);
    }
    return items;
  }

  private sortBy<R>(items: R[], fieldName: string, direction: number): R[] {
    return items.slice(0).sort((a: any, b: any) => {
      return (a[fieldName] > b[fieldName] ? 1 : a[fieldName] < b[fieldName] ? -1 : 0) * direction;
    });
  }
}
