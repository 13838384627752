export enum LegacyAssetTypes {
  TRUCK = 'truck',
  TRAILER = 'trailer'
}

export enum AssetType {
  CAR = 'car',
  TRUCK = 'truck',
  TRAILER = 'trailer',
}

export enum AssetPullingStatus {
  PULLING = 'pulling',
  PULLED = 'pulled',
}
