export enum LegacyShipmentStopTaskStatuses {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  FAILED = 'failed',
  SKIPPED = 'skipped'
}

export enum ShipmentStopTaskStatus {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  FAILED = 'failed',
  SKIPPED = 'skipped',
}

export enum ShipmentStopTaskType {
  LOAD = 'load',
  UNLOAD = 'unload',
  REFUELING = 'refueling',    // not part of this version
  BREAK = 'break',            // not part of this version
  SERVICE = 'service',        // not part of this version
  SCAN_DOCUMENT = 'scan_document',
  SCAN_DOCUMENT_COMP = 'scan document'
}
