import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {CreatePdfModes, DocumentTypes, Paths, StorageKeys, ToasterTypes} from '@app-enums';
import {ConversionService, DataService, PdfService, StorageService, ToasterService} from '@app-services';
import {BehaviorSubject, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DocumentScanner, ResponseType} from 'capacitor-document-scanner';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {format} from 'date-fns';
import {Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {DOC_ORIENTATION, NgxImageCompressService} from 'ngx-image-compress';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-create-pdf',
  templateUrl: './create-pdf.component.html',
  styleUrls: ['./create-pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePdfComponent implements OnInit, OnDestroy {

  protected previousRoute = '';

  protected base64Images$ = new BehaviorSubject<SafeResourceUrl[]>(null);
  private images: SafeResourceUrl[];
  protected readonly documentTypeSelectOptions: DocumentTypes[] = [DocumentTypes.PROOF_OF_DELIVERY, DocumentTypes.DAMAGE, DocumentTypes.INVOICE, DocumentTypes.CMR, DocumentTypes.PALLET, DocumentTypes.OTHER];
  protected readonly data: CreatePdfModes = CreatePdfModes.DEFAULT;

  protected readonly docTypeForm = new FormGroup({
    typeSelect: new FormControl<DocumentTypes>(DocumentTypes.CMR),
    otherInput: new FormControl(''),
  });

  protected readonly DocumentTypes = DocumentTypes;
  protected readonly CreatePdfModes = CreatePdfModes;
  protected docTypeReadonly = false;

  private readonly unsubscribe$ = new Subject<boolean>();

  constructor(
    private readonly pdfService: PdfService,
    private readonly storageService: StorageService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly dataService: DataService,
    private readonly domSanitizer: DomSanitizer,
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly androidPermissions: AndroidPermissions,
    private readonly imageCompression: NgxImageCompressService,
    private readonly conversionService: ConversionService,
    private readonly translation: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.data = this.router.getCurrentNavigation()?.extras?.state?.mode ?? this.data;
    if (this.data === CreatePdfModes.SHIPMENT) {
      this.docTypeForm.controls.typeSelect.setValidators(Validators.required);
      this.docTypeForm.controls.typeSelect.updateValueAndValidity();
    }
    this.previousRoute = this.router.getCurrentNavigation()?.extras?.state?.previousRoute;
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        const docType = params.get('documentType');
        if(docType) {
          this.setDocTypeFromString(docType);
          this.docTypeForm.disable();
          this.docTypeReadonly = true;
        }
      });
    this.docTypeForm.controls.typeSelect.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(docType => {
      if(!docType) {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.CMR);
        return;
      }
      if (docType === DocumentTypes.OTHER) {
        this.docTypeForm.controls.otherInput.addValidators(Validators.required);
      } else {
        this.docTypeForm.controls.otherInput.removeValidators(Validators.required);
      }
      this.docTypeForm.controls.otherInput.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //#region UI Responses

  /**
   * Cancel pdf creation
   *
   * @returns void
   */
  protected onClose(): void {
    this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
  }

  /**
   * Check camera permissions
   * Ask for permissions if not allowed already
   *
   * @returns void
   */
  protected onCheckPermission(): void {
    if (this.platform.is('android') && this.platform.is('capacitor') && this.platform.is('mobile')) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionCheck) => {
        if (permissionCheck.hasPermission) {
          this.addPhoto();
        } else {
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionRequest) => {
            if (permissionRequest.hasPermission) {
              this.addPhoto();
            }
          });
        }
      });
    } else {
      this.addPhoto();
    }
  }

  /**
   * Go back to previous Page
   *
   * @returns void
   */
  protected onGoBack(): void {
    this.router.navigateByUrl(this.previousRoute, { replaceUrl: true });
  }

  /**
   * Add photo to list of existing images.
   *
   * @returns void
   */
  protected async addPhoto(): Promise<void> {
    if (this.platform.is('android') && this.platform.is('capacitor') && this.platform.is('mobile')) {
      const photo = await DocumentScanner.scanDocument({ responseType: ResponseType.Base64, maxNumDocuments: 1 });
      const imgData = 'data:image/jpeg;base64,' + photo.scannedImages[0];

      const compressedImage = await this.imageCompression.compressFile(imgData, DOC_ORIENTATION.Default, 100, 80, 1500, 1500);
      const safeImage = this.domSanitizer.bypassSecurityTrustResourceUrl(compressedImage);

      this.images.push(safeImage);
      this.base64Images$.next(this.images);
    } else {
      let image: string;
      try {
        image = await this.imageCompression.uploadAndGetImageWithMaxSize(5);
      } catch (e) {
        // uploadAndGetImageWithMaxSize returns the image as error, when failing to get a image of correct size
        image = e;
      }
      let compressedImage = await this.imageCompression.compressFile(image, DOC_ORIENTATION.Default, 100, 80, 1500, 1500);
      this.images.push(compressedImage);
      this.base64Images$.next(this.images);
    }
  }

  /**
   * Removes image from list of existing images.
   *
   * @param index number
   *
   * @returns void
   */
  protected onRemovePhoto(index: number): void {
    this.images.splice(index, 1);
    this.base64Images$.next(this.images);
  }

  /**
   * Create PDF in base64 format, then upload it.
   *
   * @returns Promise<void>
   */
  protected async onCreatePdf(): Promise<void> {
    switch (this.data) {
      case CreatePdfModes.DEFAULT:
        this.uploadPdfToChat();
        break;
      case CreatePdfModes.SHIPMENT:
        this.addPdfToConsignment();
        break;
      default:
        break;
    }
  }

  //#endregion

  //#region Methods

  private setDocTypeFromString(docType: string) {
    switch (docType) {
      case DocumentTypes.CMR: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.CMR);
        break;
      }
      case DocumentTypes.DAMAGE: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.DAMAGE);
        break;
      }
      case DocumentTypes.INVOICE: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.INVOICE);
        break;
      }
      case DocumentTypes.PROOF_OF_DELIVERY: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.PROOF_OF_DELIVERY);
        break;
      }
      case DocumentTypes.PALLET: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.PALLET);
        break;
      }
      default: {
        this.docTypeForm.controls.typeSelect.setValue(DocumentTypes.OTHER);
        this.docTypeForm.controls.otherInput.setValue(docType);
      }
    }
  }

  /**
   * Initialize list of images.
   *
   * @returns void
   */
  private init(): void {
    this.images = [];
    this.base64Images$.next(this.images);
  }

  /**
   * Create PDF from existing images, then upload it to consignment.
   *
   * @returns Promise<void>
   */
  private async addPdfToConsignment(): Promise<void> {
    if (this.docTypeForm.invalid) {
      this.toasterService.showMessage(this.translation.instant('PDF.DocumentTypeFormError'), ToasterTypes.ERROR, 2000);
      return;
    }
    let pdf = await this.pdfService.createPdfFromImages(this.images);
    this.uploadToConsignment(pdf);
  }

  /**
   * Create PDF from existing images, then upload it to chat.
   *
   * @returns Promise<void>
   */
  private async uploadPdfToChat(): Promise<void> {
    let pdf = await this.pdfService.createPdfFromImages(this.images);
    this.uploadToChat(pdf);
  }

  //#endregion

  //#region Utilities

  /**
   * Prepare data and upload it to chat.
   *
   * @param pdfDocument PDF in base64 format
   *
   * @returns Promise<void>
   */
  private uploadToChat = async (pdfDocument: unknown): Promise<void> => {
    this.pdfService.uploadPdf(pdfDocument.toString(), CreatePdfModes.DEFAULT, '');
    this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
  };

  /**
   * Upload PDF to consignment.
   *
   * @param pdfDocument unknown
   *
   * @returns void
   */
  private uploadToConsignment = async (pdfDocument: string): Promise<void> => {
    const shipmentId = this.storageService.get(StorageKeys.SHIPMENT_ID);
    const stopId = this.storageService.get(StorageKeys.SHIPMENT_STOP_ID);
    const taskId = this.storageService.get(StorageKeys.TASK_ID);
    const date = format(new Date(), 'yyyy-MM-dd-HH-mm-ss');
    let pdfFile = await this.conversionService.convertToFile(pdfDocument, 'application/pdf', `${shipmentId}-${date}.pdf`);

    let documentType: string;
    if(this.docTypeForm.value.typeSelect !== DocumentTypes.OTHER) {
      documentType = this.docTypeForm.value.typeSelect;
    } else {
      documentType = this.docTypeForm.value.otherInput;
    }

    this.dataService.shipments.createConsignmentFile(shipmentId, stopId, taskId, pdfFile, documentType)
      .pipe().subscribe({
        next: () => {
          this.router.navigateByUrl(this.previousRoute ?? Paths.HOME_CHAT, { replaceUrl: true });
          this.toasterService.showMessage(
            this.translateService.instant('Shipments.SuccessfulUpload'),
            ToasterTypes.SUCCESS
          );
        },
        error: () => {
          this.toasterService.showMessage(
            this.translateService.instant('General.AnErrorOcurred'),
            ToasterTypes.ERROR
          );
        }
      });
  };

  //#endregion
}
