import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ChatService, ToasterService} from '@app-services';
import {TranslateService} from '@ngx-translate/core';
import {ToasterTypes} from '@app-enums';

@Component({
  selector: 'app-chat-send-message',
  templateUrl: './chat-send-message.component.html',
  styleUrls: ['./chat-send-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSendMessageComponent {

  protected message = '';

  constructor(
    private chatService: ChatService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) { }

  //#region UI Responses

  /**
   * Send a chat message.
   *
   * @returns Promise<any>
   */
  public async onSendMessage(): Promise<any> {
    if (this.message) {
      this.chatService.sendTextMessage(this.message)
        .subscribe({
          error: () => {
            this.toasterService.showMessage(this.translateService.instant('Chat.ErrorSendingMessage'), ToasterTypes.ERROR);
          }
        });
      this.message = '';
      this.cdr.detectChanges();
    }
  }

  //#endregion

}
