import {Component, Input} from '@angular/core';
import {ShipmentStopStatus} from "@app-enums";

@Component({
  selector: 'app-shipment-card-timestamps',
  templateUrl: './shipment-card-timestamps.component.html',
  styleUrls: ['./shipment-card-timestamps.component.scss'],
})
export class ShipmentCardTimestampsComponent   {

  @Input()
  set from(date: Date | string | number) {
    if(!date) {
      return;
    }
    this.startDate = new Date(date);
    this.setFormats();
  }
  @Input()
  set to(date: Date | string | number) {
    if(!date) {
      return;
    }
    this.endDate = new Date(date);
    this.setFormats();
  }

  @Input() status: ShipmentStopStatus = ShipmentStopStatus.UPCOMING;

  private readonly defaultFormat = 'dd.MM.yyyy, HH:mm';
  protected startDate: Date;
  protected endDate: Date;
  protected startDateFormat: string = this.defaultFormat;
  protected endDateFormat: string = this.defaultFormat;
  constructor() { }

  private setFormats() {
    if(!this.startDate || !this.endDate) {
      return;
    }
    const sameDay = this.startDate.getDate() === this.endDate.getDate() && this.startDate.getMonth() === this.endDate.getMonth() && this.startDate.getFullYear() === this.endDate.getFullYear();
    this.startDateFormat = 'dd.MM.yyyy, HH:mm';
    if(sameDay) {
      this.endDateFormat = 'HH:mm';
    } else {
      this.endDateFormat = 'dd.MM.yyyy, HH:mm';
    }
  }

}
