import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ThemeVariant, ThemeVariantMode } from './Theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public activeThemeVariant!: ThemeVariant;
  public activeThemeVariantMode!: ThemeVariantMode;

  private themeChanged = new BehaviorSubject<ThemeVariant | undefined>(undefined);
  public themeChanged$ = this.themeChanged.asObservable();

  private preferredColorSchemeListener?: () => void;

  public init(variantMode: ThemeVariantMode = ThemeVariantMode.Auto) {
    this.activeThemeVariantMode = variantMode;

    if (variantMode === ThemeVariantMode.Auto && !this.preferredColorSchemeListener) {
      this.preferredColorSchemeListener = () => this.updateActiveTheme();
      ThemeService.getPreferredColorSchemeMediaQuery().addEventListener('change', this.preferredColorSchemeListener);
    } else if (variantMode !== ThemeVariantMode.Auto && this.preferredColorSchemeListener) {
      ThemeService.getPreferredColorSchemeMediaQuery().removeEventListener('change', this.preferredColorSchemeListener);
    }

    this.updateActiveTheme();
  }

  public setActiveVariantMode(variantMode: ThemeVariantMode) {
    this.activeThemeVariantMode = variantMode;
    this.updateActiveTheme();
  }

  public updateActiveTheme() {
    this.activeThemeVariant = this.getThemeVariantForMode(this.activeThemeVariantMode);

    // set the theme class on the document body
    document.body.classList.remove(ThemeVariant.Light, ThemeVariant.Dark);
    document.body.classList.add(this.activeThemeVariant);

    this.themeChanged.next(this.activeThemeVariant);
  }

  private getThemeVariantForMode(variantMode: ThemeVariantMode) {
    switch (variantMode) {
      case ThemeVariantMode.Light:
        return ThemeVariant.Light;
      case ThemeVariantMode.Dark:
        return ThemeVariant.Dark;
      case ThemeVariantMode.Auto:
        return ThemeService.checkPrefersDarkMode() ? ThemeVariant.Dark : ThemeVariant.Light;
    }
  }

  private static checkPrefersDarkMode() {
    return this.getPreferredColorSchemeMediaQuery().matches;
  }

  private static getPreferredColorSchemeMediaQuery() {
    return window.matchMedia('(prefers-color-scheme: dark)');
  }
}
