import { IndexableObject } from '../../types';

export class Filter<Entity extends IndexableObject = IndexableObject, FieldType = any> {
  constructor(
    public fieldName: keyof Entity,
    public value?: FieldType,
    public operator: FilterOperator = FilterOperator.Equals
  ) {
  }

  public toString() {
    return `${String(this.fieldName)}${this.operator}${this.value}`;
  }
}

export enum FilterOperator {
  Equals = 'Equal',
  NotEquals = 'NotEquals',
  Contains = 'Contains',
  LowerThan = 'LowerThan',
  GreaterThan = 'GreaterThan',
  In = 'In',
}
