import { AdvancedHttpClient, Filter, Pagination, Sort, WebDataRepository } from '@libs/shared/data-access';
import { PhonebookEntry } from '@app-models';
import { IPhonebookEntryRepository } from './IPhonebookEntryRepository';
import { Observable } from 'rxjs';

export class PhonebookEntryRepository extends WebDataRepository<PhonebookEntry> implements IPhonebookEntryRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'phonebook');
  }

  public getAll(filters?: Filter[], sort?: Sort<PhonebookEntry>[], pagination?: Pagination, path?: string): Observable<PhonebookEntry[]> {
    return super.getAll(filters, sort, pagination, 'list');
  }
}
