import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  CameraService,
  DataService,
  LoaderService,
  PlatformService,
  SelectedTruckService,
  StorageService,
  ToasterService,
  TrailerService,
} from '@app-services';
import { first } from 'rxjs/operators';
import { Asset } from '@app-models';
import { BehaviorSubject } from 'rxjs';
import { LegacyAssetTypes, LoaderConsumersKeys, StorageKeys, ToasterTypes } from '@app-enums';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import {
  ImagePreviewFullscreenComponent,
  ImagePreviewFullScreenData
} from '../image-preview-fullscreen/image-preview-fullscreen.component';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-trailer-management',
  templateUrl: './trailer-management.component.html',
  styleUrls: ['./trailer-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrailerManagementComponent implements OnInit, OnDestroy {
  public trailer$ = new BehaviorSubject<Asset>(null);
  public trailerImages$ = new BehaviorSubject<any>(null);
  public selectedImage$ = new BehaviorSubject<any>(null);
  public availableAssets$ = new BehaviorSubject<Asset[]>(null);
  public selectedTrailerAssetID: any;
  private unsubscribe$ = new BehaviorSubject<boolean>(false);

  constructor(
    private trailerService: TrailerService,
    private dataService: DataService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private selectedTruckService: SelectedTruckService,
    private storageService: StorageService,
    private cameraService: CameraService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private androidPermissions: AndroidPermissions,
    private platformService: PlatformService,
    private imageCompression: NgxImageCompressService,
  ) {}

  ngOnInit() {
    this.getTrailerDetails();
    this.getAvailableAssets();
    this.subToImageSelect();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  //#region UI Responses

  /**
   * Check camera permissions
   * Ask for permissions if not allowed already
   *
   * @returns void
   */
  public onCheckPermissions(): void {
    if (this.platformService.isMobileApp()) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionCheck) => {
        if (permissionCheck.hasPermission) {
          this.takeTrailerPhoto();
        } else {
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionRequest) => {
            if (permissionRequest.hasPermission) {
              this.takeTrailerPhoto();
            }
          });
        }
      });
    } else {
      this.takeTrailerPhoto();
    }
  }

  /**
   * Detach the Trailer from current Truck.
   *
   * @returns void
   */
  public onDetachTrailer(): void {
    this.trailerService.detachTrailer(this.trailer$.value.assetId).then(
      () => {
        this.storageService.remove(StorageKeys.TRAILER_ID);
        this.selectedTruckService.trailerId = null;
      },
      () => {
        this.toasterService.showMessage(this.translateService.instant('General.AnErrorOcurred'), ToasterTypes.ERROR);
      },
    );
  }

  /**
   * Attach the Trailer to the current Truck.
   *
   * @returns void
   */
  public onAttachTrailer(): void {
    this.trailerService.attachTrailer(this.selectedTrailerAssetID).then(
      () => {
        this.storageService.set(StorageKeys.TRAILER_ID, this.selectedTrailerAssetID);
        this.selectedTruckService.trailerId = this.selectedTrailerAssetID;
      },
      () => {
        this.toasterService.showMessage(this.translateService.instant('General.AnErrorOcurred'), ToasterTypes.ERROR);
      },
    );
  }

  public onSelectImage(image: any): void {
    this.selectedImage$.next(image.fileUrl);
  }

  public onImageClosed(): void {
    this.selectedImage$.next(null);
  }

  //#endregion

  //#region Methods

  /**
   * Attach a photo to the trailer.
   *
   * @returns Promise<void>
   */
  public async takeTrailerPhoto(): Promise<void> {
    const user = await this.authService.userData;
    const image = await this.cameraService.takePhoto();
    const thumb = await this.imageCompression.compressFile(image.dataUrl, DOC_ORIENTATION.Default, 80, 80, 1500, 1500);

    this.loaderService.showLoader(LoaderConsumersKeys.TRAILER_POPUP);
    this.trailerService
      .takeTrailerPhoto(image.dataUrl, this.trailer$.value.assetId, user.uid, thumb)
      .then(() => {
        this.loaderService.hideLoader(LoaderConsumersKeys.TRAILER_POPUP);
      })
      .catch(() => {
        this.loaderService.hideLoader(LoaderConsumersKeys.TRAILER_POPUP);
        this.toasterService.showMessage(this.translateService.instant('General.AnErrorOcurred'), ToasterTypes.ERROR);
      });
  }

  /**
   * Get Trailer details based on the Truck ID.
   *
   * @returns void
   */
  private getTrailerDetails(): void {
    this.selectedTruckService.getAssetDetailsById(this.selectedTruckService.truckId).subscribe((truckDetails) => {
      this.handleTruckDetails(truckDetails);
    });
  }

  /**
   * Get trailer images based on trailer ID.
   *
   * @returns void
   */
  private getTrailerImages(trailerId: string | null = null): void {
    this.trailerService
      .getTrailerImages(trailerId)
      .pipe(first())
      .subscribe((images) => {
        this.trailerImages$.next(images);
      });
  }

  /**
   * Get list of all available assets.
   *
   * @returns void
   */
  private getAvailableAssets(): void {
    this.dataService.assets
      .getAll()
      .pipe(first())
      .subscribe((assets) => {
        this.handleAvailableAssetsList(assets as Asset[]);
      });
  }

  //#endregion

  //#region Handlers

  private subToImageSelect() {
    this.selectedImage$.subscribe((value) => {
      if (value) {
        const data: ImagePreviewFullScreenData = {
          src: value
        };
        this.dialog.open(ImagePreviewFullscreenComponent, {
          data,
          maxWidth: '100vw',
          maxHeight: '100vh',
          panelClass: 'full-preview',
        });
      }
    });
  }

  /**
   * Prepare list of trailers for Trailer-select drop down.
   *
   * @param assetList Asset[]
   *
   * @returns void
   */
  private handleAvailableAssetsList(assetList: Asset[]): void {
    const trailers = assetList.filter((x) => x.type === LegacyAssetTypes.TRAILER);
    this.availableAssets$.next(trailers);
  }

  /**
   * Update UI by emitting Trailer details. If no trailer for the Truck given, emit null.
   *
   * @param truckDetails Asset
   *
   * @returns void
   */
  private handleTruckDetails(truckDetails: Asset): void {
    setTimeout(() => {
      if (truckDetails.assetIDAfter) {
        this.trailerService.getTrailerDetails(truckDetails.assetIDAfter).subscribe(this.handleTrailerDetails);
      } else {
        this.trailer$.next(null);
      }
    });
  }

  /**
   * Update trailer info and photos.
   *
   * @param trailer Asset
   *
   * @returns void
   */
  private handleTrailerDetails = (trailer: Asset): void => {
    this.getTrailerImages(trailer.assetId);
    this.trailer$.next(trailer);
  };

  //#endregion
}
