// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-shipment {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  padding: 20px 0px;
  justify-content: center;
  color: var(--color-error-500);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNoaXBtZW50LXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxrQkFBQTtFQUNBLG1CQUFBO0VBQ0EsaUJBQUE7RUFDQSx1QkFBQTtFQUNBLDZCQUFBO0FBQ0YiLCJmaWxlIjoic2hpcG1lbnQtdmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5uby1zaGlwbWVudCB7XG4gIGZvbnQtc2l6ZTogMjBweDtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgcGFkZGluZzogMjBweCAwcHg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBjb2xvcjogdmFyKC0tY29sb3ItZXJyb3ItNTAwKTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/web/src/app/modules/home/components/shipment-view/shipment-view.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,6BAAA;AACF;AACA,omBAAomB","sourcesContent":[".no-shipment {\n  font-size: 20px;\n  font-weight: bold;\n  text-align: center;\n  align-items: center;\n  padding: 20px 0px;\n  justify-content: center;\n  color: var(--color-error-500);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
