import { FakeRepository } from '@libs/shared/data-access';
import { IEventRepository } from './IEventRepository';
import { Observable } from 'rxjs';
import { CreateEventDto } from '@app-models';

const fakeEvents: CreateEventDto[] = [];

export class FakeEventRepository extends FakeRepository<CreateEventDto> implements IEventRepository {
  constructor() {
    super(fakeEvents);
  }

  public add(data: CreateEventDto): Observable<void> {
    return undefined;
  }
}
