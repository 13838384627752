import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, SecurityContext} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {DOC_ORIENTATION, NgxImageCompressService} from 'ngx-image-compress';
import {CameraService, PlatformService} from '@app-services';
import {DomSanitizer} from '@angular/platform-browser';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {
  ImagePreviewFullscreenComponent, ImagePreviewFullScreenData
} from '../../../popups/image-preview-fullscreen/image-preview-fullscreen.component';
import {MatDialog} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-event-form-file',
  standalone: true,
  imports: [CommonModule, MatIcon, TranslateModule, MatButton],
  templateUrl: './event-form-file.component.html',
  styleUrls: ['./event-form-file.component.scss', '../event-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EventFormFileComponent),
    multi: true
  }]
})
export class EventFormFileComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() description: string;
  @Input() required = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  protected value: string;

  private onChange?: (val: string) => void;
  private onTouched?: () => void;

  constructor(
    private readonly platformService: PlatformService,
    private readonly cameraService: CameraService,
    private readonly domSanitizer: DomSanitizer,
    private readonly imageCompression: NgxImageCompressService,
    private readonly androidPermission: AndroidPermissions,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
    if(this.onChange) {
      this.onChange(this.value);
    }
    this.cdr.detectChanges();
  }

  /**
   * Take a Photo and add to form
   *
   * @returns void
   */
  protected onAddImage(): void {
    this.onTouched?.();
    if (this.platformService.isMobileApp()) {
      this.androidPermission.requestPermission(this.androidPermission.PERMISSION.CAMERA).then((permissionRequest) => {
        if (permissionRequest.hasPermission) {
          this.takeAPhoto();
        }
      });
    } else {
      this.takeAPhoto();
    }
  }
  /**
   * Remove image from the array
   */
  protected onRemoveImage(event?: Event): void {
    if(event) {
      event.stopPropagation();
    }
    this.writeValue('');
  }

  /**
   * Fullscreen the image
   */
  public onPreviewImage(): void {
    const data: ImagePreviewFullScreenData = {
      src: this.value
    };
    this.dialog.open(ImagePreviewFullscreenComponent, {
      data,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      panelClass: 'full-preview',
    });
  }

  /**
   * Open camera(mobile) or upload(web) image
   *
   * @returns void
   */
  private takeAPhoto(): void {
    this.cameraService.takePhoto().then(async (value) => {
      const compressedImage = await this.imageCompression.compressFile(value.dataUrl, DOC_ORIENTATION.Default, 80, 80, 1500, 1500);
      const srcUrl = this.domSanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.domSanitizer.bypassSecurityTrustResourceUrl(compressedImage),
      );
      this.writeValue(srcUrl);
    });
  }
}
