import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ContactsService, DataService, ToasterService } from '@app-services';
import { PhonebookEntry } from '@app-models';
import { TranslateService } from '@ngx-translate/core';
import { ToasterTypes } from '@app-enums';

@Component({
  selector: 'app-phonebook',
  templateUrl: './phonebook.component.html',
  styleUrls: ['./phonebook.component.scss'],
})
export class PhonebookComponent implements OnInit {
  public phonebookList$ = new BehaviorSubject<any[]>(null);
  private initPhonebookList: PhonebookEntry[];
  private phonebookArray: any[];
  private alphabetArray: string[];
  public synced$ = new BehaviorSubject<boolean>(null);

  constructor(
    private dataService: DataService,
    private router: Router,
    private contactsService: ContactsService,
    private toasterService: ToasterService,
    private translationService: TranslateService,
  ) {}

  //#region lifecycle

  ngOnInit() {
    this.initList();
  }

  //#endregion

  //#region initialization

  /**
   * Get users list, init list separators and add them to contact list
   *
   * @returns void
   */
  private initList(): void {
    this.dataService.phonebookEntries
      .getAll()
      .pipe(first())
      .subscribe((phonebookList) => {
        this.initPhonebookList = [...phonebookList];
        this.phonebookArray = phonebookList;
        this.initListSeparators(phonebookList);
        this.phonebookList$.next(this.phonebookArray.sort((a, b) => ((a.name ? a.name : a) > (b.name ? b.name : b) ? 1 : -1)));
        this.contactsService.checkPermissions().then((permission) => {
          if (permission.hasPermission) {
            this.contactsService.checkIfSynced(this.initPhonebookList).then((value) => {
              this.synced$.next(value);
            });
          } else {
            this.toasterService.showMessage(this.translationService.instant('General.Messages.NoContactPermission'), ToasterTypes.WARNING);
          }
        });
      });
  }

  /**
   * Get all of the letters without repeating for list separating
   *
   * @param phonebookList phoneBookListEntity
   */
  private initListSeparators(phonebookList, list = this.phonebookArray): void {
    this.alphabetArray = phonebookList
      .map((x) => x.name[0].toUpperCase())
      .filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });
    this.alphabetArray.forEach((element) => {
      list.push(element);
    });
  }

  //#endregion

  //#region UI response

  /**
   *
   * @returns Promise<void>
   */
  public async onSyncContacts(): Promise<void> {
    if (!this.synced$.value) {
      await this.contactsService.syncContacts(this.initPhonebookList).then(() => {
        this.contactsService.checkIfSynced(this.initPhonebookList).then((value) => {
          this.synced$.next(value);
        });
      });
    } else {
      this.contactsService.alreadySynced();
    }
  }

  /**
   * Navigate to contact details unless it's a letter used for separation of the list
   *
   * @param contactId string
   */
  public onGoToContactDetails(contactId): void {
    if (contactId) {
      this.router.navigateByUrl('home/phonebook/' + contactId);
    }
  }

  public onInput(event) {
    this.removeSeparators();
    this.phonebookList$.next(
      this.phonebookArray.filter((x) => JSON.stringify(Object.values(x)).toLowerCase().includes(event.value.toLowerCase())),
    );
    this.initListSeparators(this.phonebookList$.value, this.phonebookList$.value);
    this.phonebookList$.next(this.phonebookList$.value.sort((a, b) => ((a.name ? a.name : a) > (b.name ? b.name : b) ? 1 : -1)));
  }

  //#endregion

  private removeSeparators() {
    this.phonebookArray = this.phonebookArray.filter((x) => x.name && x.name.length > 0);
  }
}
