import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Paths, StorageKeys} from '@app-enums';
import {ShipmentService, StorageService} from '@app-services';
import {BehaviorSubject} from 'rxjs';
import {Shipment, ShipmentStop} from '@app-models';

@Component({
  selector: 'app-shipment-stops',
  templateUrl: './shipment-stops.component.html',
  styleUrls: ['./shipment-stops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentStopsComponent implements OnInit {

  public stops$ = new BehaviorSubject<ShipmentStop[]>(null);

  protected readonly defaultDateFormat = 'dd.MM.yyyy, HH:mm';

  constructor(
    private router: Router,
    private shipmentService: ShipmentService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.getShipment();
  }

  //#region UI responses

  /**
   * Back to shipments list.
   *
   * @returns void
   */
  public onGoBack(): void {
    this.storageService.remove(StorageKeys.SHIPMENT_ID);
    this.router.navigateByUrl(Paths.HOME_TRIPS_AND_SHIPMENTS, { replaceUrl: true });
  }

  /**
   * Redirects User to Google maps.
   *
   * @param stop Shipment Stop
   *
   * @returns void
   */
  public onGoToMaps(stop: ShipmentStop, event: Event): void {
    event.stopPropagation();
    const url = `https://www.google.com/maps/search/${stop.coordinates.latitude},${stop.coordinates.longitude}/@${stop.coordinates.latitude},${stop.coordinates.longitude},14z`;
    window.open(url, "_new");
  }

  /**
   * Open stop tasks page.
   *
   * @param stop ShipmentStop
   *
   * @returns void
   */
  public onOpenStop(stop: ShipmentStop): void {
    this.storageService.set(StorageKeys.SHIPMENT_STOP_ID, stop.id);
    this.storageService.set(StorageKeys.SHIPMENT_STOP_NAME, stop.name);
    this.router.navigateByUrl(Paths.HOME_SHIPMENT_STOPS_TASKS, { replaceUrl: true });
  }

  //#endregion

  //#region Methods

  private getShipment(): void {
    const shipmentId = this.storageService.get(StorageKeys.SHIPMENT_ID);
    this.shipmentService.getShipmentById(shipmentId).subscribe(this.handleShipmentDetails);
    this.shipmentService.onShipmentUpdate(shipmentId, this.handleShipmentDetails);
  }

  //#endregion

  //#region Handlers

  /**
   * Update UI with the list of shipment stops.
   *
   * @param shipment Shipment
   *
   * @returns void
   */
  private handleShipmentDetails = (shipment: Shipment): void => {
    this.stops$.next(shipment.stops);
  };

  //#endregion

}
