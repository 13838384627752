import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoaderService, ShipmentService, StorageService } from '@app-services';
import { LegacyShipment } from '@app-models';
import { BehaviorSubject } from 'rxjs';
import { LoaderConsumersKeys, Paths, StorageKeys } from '@app-enums';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shipment-view',
  templateUrl: './shipment-view.component.html',
  styleUrls: ['./shipment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentViewComponent implements OnInit {

  public shipments$ = new BehaviorSubject<LegacyShipment[]>(null);

  constructor(
    private shipmentService: ShipmentService,
    private loaderService: LoaderService,
    private storageService: StorageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getShipments();
  }

  //#region UI Responses

  /**
   * Display all stops from the shipment selected.
   *
   * @param shipment Shipment
   *
   * @returns void
   */
  public onGoToShipment(shipment: LegacyShipment): void {
    this.storageService.set(StorageKeys.SHIPMENT_ID, shipment.shipmentId);
    this.router.navigateByUrl(Paths.HOME_SHIPMENT_STOPS, { replaceUrl: true });
  }

  //#endregion

  //#region Methods

  /**
   * Get all shipments from AFS.
   *
   * @returns void
   */
  private getShipments(): void {
    this.loaderService.showLoader(LoaderConsumersKeys.GET_SHIPMENTS);
    const truckId = this.storageService.get(StorageKeys.TRUCK_ID);
    if (truckId) {
      this.shipmentService.getShipments(truckId).subscribe(
        {
          next: shipments => {
            this.handleShipments(shipments);
            shipments.forEach(shipment => this.shipmentService.onShipmentUpdate(shipment.shipmentId, this.handleShipments));
          },
          error: () => {
            this.handleShipments([]);
          }
        });
    }
  }

  //#endregion

  //#region Handlers

  /**
   * Update UI with the latest set of Shipments.
   *
   * @param shipments Shipment[]
   *
   * @returns void
   */
  private handleShipments = (shipments: LegacyShipment[]): void => {
    shipments.forEach(shipment => shipment.stops = shipment.stops?.filter(stop => stop.$type !== 'waypoint'));
    shipments.sort((a, b) => {
      if(!a.stops || !b.stops) {
        return 0;
      }
      const aArrival = a.stops[0].timeWindow.start;
      const bArrival = b.stops[0].timeWindow.start;
      return aArrival - bArrival;
    });
    this.shipments$.next(shipments);
    this.loaderService.hideLoader(LoaderConsumersKeys.GET_SHIPMENTS);
  };

  //#endregion

}
