export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export class Sort<Entity = any> {
  constructor(
    public fieldName: keyof Entity,
    public direction: SortDirection = SortDirection.Ascending
  ) {
  }

  public toString() {
    return this.direction + String(this.fieldName);
  }
}
