export enum ThemeVariant {
  Light = 'light',
  Dark = 'dark',
}

export enum ThemeVariantMode {
  Light = ThemeVariant.Light,
  Dark = ThemeVariant.Dark,
  Auto = 'auto', // choose according to operating system
}
