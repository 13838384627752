import { Component, Input } from '@angular/core';
import { LegacyShipment } from '@app-models';

@Component({
  selector: 'app-shipment-view-card',
  templateUrl: './shipment-view-card.component.html',
  styleUrls: ['./shipment-view-card.component.scss'],
})
export class ShipmentViewCardComponent {
  @Input() set shipment(shipment: LegacyShipment) {
    this._shipment = shipment;
    this.hasStops = shipment.stops.length > 0;
    if (!this.hasStops) {
      return;
    }
    const firstStop = shipment.stops[0];
    const lastStop = shipment.stops[shipment.stops.length - 1];
    const firstDateSec = firstStop.timeWindowHistoric?.start ?? firstStop.timeWindow.start;
    const lastDateSec = lastStop.timeWindowHistoric?.end ?? lastStop.timeWindow.end;
    this.startDate = new Date(firstDateSec * 1000);
    this.endDate = new Date(lastDateSec * 1000);
  }
  get shipment() {
    return this._shipment;
  }
  private _shipment: LegacyShipment;

  protected hasStops: boolean;
  protected startDate: Date;
  protected endDate: Date;

  constructor() {}
}
