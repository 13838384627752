import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule, SharedModule } from '@app-shared-modules';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor, HttpErrorInterceptor, LoaderInterceptor } from '@app-interceptors';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent, NetworkPopupComponent } from '@app-shared-components';
import { AuthModule } from './modules/auth/auth.module';
import { HomeModule } from './modules/home/home.module';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/compat/storage';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { DataService, NetworkService, WebApiDataService } from '@app-services';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { sentryProviders } from './core/providers/sentry';
import { ENVIRONMENT, EnvironmentStage } from '@libs/shared/environment';
import { environment } from '@app-environments/environment';
import { IMobileAppEnvironment } from '@app-environments/IMobileAppEnvironment';
import { BaseDataService } from '@libs/shared/data-access';
import {AngularFireMessagingModule, VAPID_KEY, SERVICE_WORKER} from '@angular/fire/compat/messaging';
import { NotificationPopupComponent } from './shared/components/notification-popup/notification-popup.component';

const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

const config: SocketIoConfig = {
  url: environment.api.baseUrl,
  options: {
    transports: ['websocket', 'polling'],
  },
};

export function DataServiceFactory(http: HttpClient, env: IMobileAppEnvironment) {
  // if (env.stage !== EnvironmentStage.PRODUCTION) {
  //   return new FakeApiDataService();
  // }
  return new WebApiDataService(http, env);
}

export function BaseDataServiceFactory(dataService: DataService) {
  return dataService;
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, NetworkPopupComponent, NotificationPopupComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    HomeModule,
    AngularMaterialModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireMessagingModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('combined-sw.js', {
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      enabled: environment.stage !== EnvironmentStage.LOCAL,
      registrationStrategy: 'registerImmediately',
    }),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    TranslateService,
    NetworkService,
    CallNumber,
    AngularFirestore,
    AndroidPermissions,
    AngularFireStorage,
    Vibration,
    Camera,
    ...sentryProviders,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: DataService,
      useFactory: DataServiceFactory,
      deps: [HttpClient, ENVIRONMENT],
    },
    {
      provide: BaseDataService,
      useFactory: BaseDataServiceFactory,
      deps: [DataService],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    { provide: 'SESSIONSTORAGE', useValue: window.sessionStorage },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: VAPID_KEY, useValue: 'BGhDurs1d2Ve_SLidOZgOStXrBV-5VirmJNqRRgspH97sA7Ij4FJ-wQcMa-UhNQ4EeP7Mw627oAhGjR6YBFlpg8'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
