export enum ShipmentStatus {
  incomplete = 'incomplete',
  cancelled = 'cancelled',
  pending = 'pending',
  upcoming = 'upcoming',
  inProgress = 'in_progress',
  finished = 'finished',
  finishedByFollowingShipment = 'finished_by_following_shipment',
  archive = 'archive',
  error = 'error',
  actionRequired = 'actionRequired',
  tooLate = 'tooLate'
}
