// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlet-wrapper {
  height: calc(100% - var(--mat-toolbar-standard-height)) !important;
  bottom: 0px;
  position: relative;
}

@media (max-width: 599px) {
  .outlet-wrapper {
    height: calc(100% - var(--mat-toolbar-mobile-height)) !important;
  }
}
.full-height-wrapper {
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGtFQUFBO0VBQ0EsV0FBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBRUE7RUFDRTtJQUNFLGdFQUFBO0VBQ0Y7QUFDRjtBQUVBO0VBQ0UsWUFBQTtBQUFGIiwiZmlsZSI6ImFwcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5vdXRsZXQtd3JhcHBlciB7XG4gIGhlaWdodDogY2FsYygxMDAlIC0gdmFyKC0tbWF0LXRvb2xiYXItc3RhbmRhcmQtaGVpZ2h0KSkgIWltcG9ydGFudDsgLy8gNTZweCBmb3IgaGVhZGVyXG4gIGJvdHRvbTogMHB4O1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1OTlweCkge1xuICAub3V0bGV0LXdyYXBwZXIge1xuICAgIGhlaWdodDogY2FsYygxMDAlIC0gdmFyKC0tbWF0LXRvb2xiYXItbW9iaWxlLWhlaWdodCkpICFpbXBvcnRhbnQ7XG4gIH1cbn1cblxuLmZ1bGwtaGVpZ2h0LXdyYXBwZXIge1xuICBoZWlnaHQ6IDEwMCU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/web/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,kEAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE;IACE,gEAAA;EACF;AACF;AAEA;EACE,YAAA;AAAF;AACA,wwBAAwwB","sourcesContent":[".outlet-wrapper {\n  height: calc(100% - var(--mat-toolbar-standard-height)) !important; // 56px for header\n  bottom: 0px;\n  position: relative;\n}\n\n@media (max-width: 599px) {\n  .outlet-wrapper {\n    height: calc(100% - var(--mat-toolbar-mobile-height)) !important;\n  }\n}\n\n.full-height-wrapper {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
