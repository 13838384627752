import { EntityId } from '@libs/shared/types';

export interface CreateEventDto {
  eventTypeId: EntityId;
  client: string;
  reference: string;
  visibleForCustomer: boolean;
  eventFormContents: CreateEventField[];
}

export interface CreateEventField {
  eventFormFieldId: number;
  content: string;
}
