import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {first, Observable, of, timeout} from 'rxjs';
import { AuthService } from '@app-services';
import { Paths } from '@app-enums';
import {catchError, filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.loggedIn$
      .pipe(
        filter(loggedIn => loggedIn !== null),
        timeout(5000),
        first(),
        catchError(() => {
          return of(false);
        }),
        map(isLoggedIn => {
          if(state.url === Paths.ROOT_AUTH) {
            if(isLoggedIn) {
              return this.router.parseUrl(Paths.HOME);
            } else {
              return true;
            }
          }
          if(!isLoggedIn) {
            return this.router.parseUrl(Paths.AUTH);
          }
          return isLoggedIn;
        })
      );
  }
}
