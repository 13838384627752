import { Observable } from 'rxjs';
import { AdvancedHttpClient, WebDataRepository, IUserRepository, Filter, Pagination, Sort } from '@libs/shared/data-access';
import { EntityId } from '@libs/shared/types';
import { RefreshTokenDto, User } from '@libs/shared/models';

export class UserRepository extends WebDataRepository<User> implements IUserRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'users');
  }

  public login(email: string, password: string): Observable<User> {
    return this.httpClient.post<User, { email: string; password: string }>({
      url: `${this.resource}/auth`,
      body: { email, password }
    });
  }

  public logout(): Observable<void> {
    return this.httpClient.post<void, void>({ url: `${this.resource}/logoutFromAsset` });
  }

  public refreshTokens(refreshToken: string): Observable<RefreshTokenDto> {
    return this.httpClient.post<RefreshTokenDto, { refresh_token: string }>({
      url: `${this.resource}/refresh-token`,
      body: { refresh_token: refreshToken }
    });
  }

  public override getAll(filters?: Filter[], sort?: Sort<User>[], pagination?: Pagination): Observable<User[]> {
    throw new Error('Method not supported.');
  }

  public override getById(id: EntityId): Observable<User> {
    throw new Error('Method not supported.');
  }
}
