export enum LoaderConsumersKeys {
  HTTP = 'http',
  HEADER = 'header',
  LOGIN = 'login',
  GET_SHIPMENTS = 'get_shipments',
  TRIP_SAVE = 'trip_save',
  GET_TRIPS = 'get_trips',
  TRAILER_POPUP = 'trailer_popup',
  UPLOAD_PDF = 'upload_pdf',
  PDF_SERVICE = 'pdf_service',
  UPLOAD_IMAGE = 'upload_image'
}
