import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@app-services';
import { EntityId } from '@libs/shared/types';
import {CreateEventDto, EventType, EventTypeFormField, EventTypeFormFieldUI} from '@app-models';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  constructor(
    protected dataService: DataService,
    private formBuilder: FormBuilder,
    private translation: TranslateService,
  ) {}

  public getAllDripEventTypes(): Observable<EventType[]> {
    return this.dataService.eventTypes.getAll();
  }

  public getEventTypeById(eventTypeId: EntityId | number): Observable<EventType> {
    return this.dataService.eventTypes.getById(String(eventTypeId));
  }

  /**
   * Create dynamic form based on passed fields.
   *
   * @param fields EventHandlingFormField[]
   *
   * @returns FormGroup
   */
  public createEventDynamicForm(fields: EventTypeFormFieldUI[]): FormGroup {
    const eventDynamicForm: FormGroup = this.formBuilder.group({});

    fields.filter(field => !field.conditional).forEach((field) => {
      eventDynamicForm.addControl(
        field.formKey,
        new FormControl('', field.required ? [Validators.required] : []),
      );
    });
    eventDynamicForm.addControl(
      'visibleForCustomer', new FormControl(false, [])
    );

    return eventDynamicForm;
  }

  public addNewEvent(data: CreateEventDto) {
    return this.dataService.events.add(data);
  }

  /**
   * Returns default event form fields.
   *
   * @returns EventTypeFormField[]
   */
  public defaultFormFields(): EventTypeFormFieldUI[] {
    return [
      {
        formKey: 'default-0',
        name: this.translation.instant('eventHandler.labels.client'),
        description: '',
        type: 'text',
        required: false,
        display: true,
        id: -1,
        options: [],
      },
      {
        formKey: 'default-1',
        name: this.translation.instant('eventHandler.labels.reference'),
        description: '',
        type: 'text',
        required: false,
        display: true,
        id: -2,
        options: [],
      }
    ];
  }
}
