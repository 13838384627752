export enum Paths {

  // #region usage Routing module
  EMPTY = '',
  HOME = 'home',
  AUTH = 'auth',
  CHAT = 'chat',
  TRIPS_AND_SHIPMENTS = 'trips-and-shipments',
  CREATE_PDF = 'create-pdf',
  CHAT_PREVIEW = 'chat-preview',
  PAINT = 'paint',
  SELECT_ASSET = 'select-asset',
  // #endregion

  // #region Usage inside Components
  HOME_CHAT = 'home/chat',
  HOME_PHONEBOOK = 'home/phonebook',
  HOME_SELECT_ASSET = 'select-asset',
  HOME_CREATE_PDF = 'home/create-pdf',
  HOME_CHAT_PREVIEW = 'home/chat-preview',
  HOME_PAINT = '/home/paint',
  HOME_EVENT = 'home/events',

  HOME_TRIPS_AND_SHIPMENTS = 'home/trips-and-shipments',
  SHIPMENT_STOPS = 'shipment/stops',
  HOME_SHIPMENT_STOPS = 'home/shipment/stops',
  SHIPMENT_STOPS_TASKS = 'shipment/stops/tasks',
  HOME_SHIPMENT_STOPS_TASKS = 'home/shipment/stops/tasks',

  // #region  URL Subscriptions
  ROOT = '/',
  ROOT_HOME = '/home',
  ROOT_AUTH = '/auth',
  ROOT_TRIP = '/trip',
  ROOT_HOME_TRIPS_AND_SHIPMENTS = '/home/trips-and-shipments',
  ROOT_HOME_TRIP_STOPS = '/home/trip/stops',
  ROOT_HOME_TRIP_STOPS_TASKS = '/home/trip/stops/tasks',
  ROOT_HOME_SHIPMENT_STOPS = '/home/shipment/stops',
  ROOT_HOME_SHIPMENT_STOPS_TASKS = '/home/shipment/stops/tasks'
  // #endregion

}
