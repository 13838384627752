import { FakeRepository } from '@libs/shared/data-access';
import {
  LegacyShipment,
  LegacyShipmentStopTask,
  Shipment,
  ShipmentStop,
  TaskStatusChangeParams
} from '@app-models';
import { IShipmentRepository } from './IShipmentRepository';
import { EntityId } from '@libs/shared/types';
import { Observable } from 'rxjs';

const fakeShipments: Shipment[] = [];

export class FakeShipmentRepository extends FakeRepository<Shipment> implements IShipmentRepository {
  constructor() {
    super(fakeShipments);
  }

  public getShipmentTasks(shipmentId: EntityId<Shipment>, stopId: EntityId<ShipmentStop>): Observable<LegacyShipmentStopTask[]> {
    return undefined;
  }

  public getShipmentsByTruckId(truckId: EntityId): Observable<LegacyShipment[]> {
    return undefined;
  }

  public hasShipments(truckId: EntityId): Observable<boolean> {
    return undefined;
  }

  public changeShipmentTaskStatus(
    shipmentId: string,
    stopId: string,
    taskId: string,
    params: TaskStatusChangeParams,
  ): Observable<LegacyShipmentStopTask> {
    return undefined;
  }

  public createConsignmentFile(shipmentId: string, stopId: string, taskId: string, file: File, documentType: string): Observable<void> {
    return undefined;
  }
}
