import { EntityId } from '@libs/shared/types';

export interface Asset {
  assetId: EntityId;
  assetIDBefore?: EntityId;
  assetIDAfter?: EntityId;
  companyID?: EntityId;
  displayName: string;
  gateways?: any;
  hasPrinter?: boolean;
  heartbeatGatewayID?: EntityId;
  information?: any;
  licencePlate: string;
  licencePlateSimplified?: string;
  liveData?: any;
  permissions?: any;
  type: string;
  suggestedName?: string;
}
