export enum ChatMessageTypes {
  NONE = 'none',
  WARNING = 'warning',
  INFORMATION = 'information',
  CALL = 'call',
  INACTIVE_CALL = 'inactive_call',
  END_CALL = 'end_call',
  TEXT = 'text',          // backwards compatibility
  DOCUMENT = 'document',  // backwards compatibility
  IMAGE = 'image',        // backwards compatibility
}
