import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '@app-services';
import { StorageKeys } from '@app-enums';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.storageService.get(StorageKeys.TOKEN);
    if (token) {

      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + `${token}`
        }
      });
    }
    req.headers.set('Content-Type', 'application/json');
    return next.handle(req);
  }
}
