import { EntityId } from '@libs/shared/types';

export interface EventType {
  id: EntityId;
  name: string;
  fields?: EventTypeFormField[];
  icon: string;
}

export enum ConditionalFieldCondition {
  MATCHES = 'matches',
  NOT_NULL = 'notNull',
  IS_NULL = 'isNull',
}

export interface EventTypeFormField {
  id: number;
  name: string;
  description: string;
  type: string;
  required: boolean;
  content?: string[];
  options: string[];
  conditional?: {
    fieldId: number;
    condition: ConditionalFieldCondition;
    value: string;
  }
}

export interface EventTypeFormFieldUI extends EventTypeFormField {
  formKey: `field-${number}` | `default-${number}`;
  display: boolean;
}
