import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ContactsService, DataService } from '@app-services';
import { PhonebookEntry } from '@app-models';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  public contact$ = new BehaviorSubject<PhonebookEntry>(null);

  public synced$ = new BehaviorSubject<boolean>(null);

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private callNumber: CallNumber,
    private platform: Platform,
    private contactService: ContactsService,
  ) {}

  //#region lifecycle

  ngOnInit(): void {
    this.getChosenContactDetails();
  }

  //#endregion

  //#region initialization

  /**
   * get details of the user by id from the url
   *
   * @returns void
   */
  private getChosenContactDetails(): void {
    this.dataService.phonebookEntries
      .getAll()
      .pipe(first())
      .subscribe((phonebookList) => {
        this.contact$.next(phonebookList.find((x) => x.phonebookEntryId === this.activatedRoute.snapshot.params.contactId));
        this.contactService.checkIfSynced([this.contact$.value]).then((synced) => {
          this.synced$.next(synced);
        });
      });
  }

  //#endregion

  //#region UI response

  /**
   * Call the number chosen
   *
   * @returns void
   */
  public onCall(): void {
    if (!this.platform.is('pwa') && this.platform.is('android') && this.platform.is('capacitor')) {
      this.callNumber.callNumber(this.contact$.value.phoneNumber, true);
    } else {
      window.location.href = 'tel:' + this.contact$.value.phoneNumber;
    }
  }

  /**
   * Sync contacts to the phone
   *
   * @returns Promise<void>
   */
  public async onSyncContacts(): Promise<void> {
    if (!this.synced$.value) {
      await this.contactService.syncContacts([this.contact$.value]).then(() => {
        this.contactService.checkIfSynced([this.contact$.value]).then((value) => {
          this.synced$.next(value);
        });
      });
    } else {
      this.contactService.alreadySynced();
    }
  }

  /**
   * Go back to contacts
   *
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigateByUrl('home/phonebook');
  }

  //#endregion
}
