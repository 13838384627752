import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {BehaviorSubject, firstValueFrom, interval, of, Subject, Subscription} from 'rxjs';
import {ChatMessage, ChatMessageUI} from '@app-models';
import {
  AuthService,
  ChatService,
  ConversionService,
  JitsiCallService,
  LoaderService,
  PlatformService,
  PushService,
  SelectedTruckService,
  StorageService,
} from '@app-services';
import {ChatMessageTypes, LoaderConsumersKeys, StorageKeys} from '@app-enums';
import {catchError, first, map, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {
  ImagePreviewFullscreenComponent,
  ImagePreviewFullScreenData
} from '../../popups/image-preview-fullscreen/image-preview-fullscreen.component';
import {PdfViewerComponent} from '../../popups/pdf-viewer/pdf-viewer.component';
import {Keyboard} from '@capacitor/keyboard';
import {ENVIRONMENT, IEnvironment} from '@libs/shared/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesComponent implements OnInit, OnDestroy {
  @ViewChild('scrollingContainer') private scrollContainer: ElementRef<HTMLDivElement>;

  @Input() shortened: boolean = false;

  private readonly hiddenMessagesTypes: ChatMessageTypes[] = [ChatMessageTypes.CALL, ChatMessageTypes.END_CALL, ChatMessageTypes.INACTIVE_CALL];

  private readonly messages$ = new BehaviorSubject<ChatMessageUI[]>([]);
  protected readonly displayMessages$ = this.messages$.pipe(
    map(messages => {
      let hasCallMsg = false;
      for (let i = messages.length-1; i >= 0; i--) {
        if(messages[i].type === ChatMessageTypes.END_CALL) {
          hasCallMsg = true;
        }
        if(messages[i].type === ChatMessageTypes.CALL) {
          if(hasCallMsg) {
            messages[i].type = ChatMessageTypes.INACTIVE_CALL;
          } else {
            if(messages[i].datetime < Date.now() - (1000 * 60 * 10)) {
              messages[i].type = ChatMessageTypes.INACTIVE_CALL;
            }
            hasCallMsg = true;
          }
        }
      }
      return messages;
    })
  );
  protected readonly user$ = new BehaviorSubject<any>(null);
  protected readonly ChatMessageTypes = ChatMessageTypes;
  private readonly loggedOut$ = new Subject<boolean>();
  private topDivForScroll: number;

  protected readonly userActivatedScroll$ = new BehaviorSubject<boolean>(false);
  protected readonly newMessageCount$ = new BehaviorSubject<number>(undefined);

  private newMessageCheckInterval: Subscription;

  private offset = 0;
  private readonly limit = 10;
  private hasMoreMessages = true;

  private readonly unsubscribe$ = new Subject<boolean>();

  private languageLoaded: string;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: IEnvironment,
    private readonly selectedTruckService: SelectedTruckService,
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly dialog: MatDialog,
    private readonly chatHttpService: ChatService,
    private readonly cdr: ChangeDetectorRef,
    private readonly conversionService: ConversionService,
    private readonly platformService: PlatformService,
    private readonly jitsiService: JitsiCallService,
    private readonly loaderService: LoaderService,
    private readonly translateService: TranslateService,
    private readonly pushService: PushService,
  ) { }

  //#region Lifecycle hooks

  async ngOnInit() {
    this.subscribeToKeyboard();
    await this.getAllMessages();
    this.createNewMessageInterval();
    this.authService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loggedOut$.next(!value);
      });
    this.translateService.onLangChange
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        if (this.translateService.currentLang !== this.languageLoaded) {
          this.reload();
        }
      });
  }

  protected isScrolledToBottom() {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    const scrollOffset = scrollHeight - scrollTop - clientHeight;
    const nextUserScrollState = scrollOffset > 50;
    if (!nextUserScrollState && this.userActivatedScroll$.value) {
      // scrolled to bottom
      this.newMessageCount$.next(undefined);
    }
    this.userActivatedScroll$.next(nextUserScrollState);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    if (this.platformService.isMobileApp()) {
      Keyboard.removeAllListeners();
    }
    this.newMessageCheckInterval.unsubscribe();
  }

  /**
   * Checks if message with type should be displayed
   * @param type
   * @protected
   */
  protected showMesssage(type: ChatMessageTypes) {
    return !this.hiddenMessagesTypes.includes(type);
  }

  //#endregion

  //#region

  /**
   * Clears and reloads chat
   * @private
   */
  private reload() {
    this.messages$.next([]);
    this.offset = 0;
    this.getAllMessages();
  }

  protected scrollToBottom(force = false) {
    if (this.userActivatedScroll$.value && !force) {
      return;
    }
    this.scrollContainer.nativeElement.scrollTo({
      top: this.scrollContainer.nativeElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  /**
   * if there is at least one message and we currently dont have it in displayed messages
   * get all messages until we come across one we have
   *
   * @returns void
   */
  private subscribeToNewMessages(): void {
    this.chatHttpService.getMessages(this.selectedTruckService.truckId, 0, 1,false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (value) => {
        let cameAcrossAlreadyReceivedMessage = false;
        if (value[0] && !this.messages$.value.find((x) => x.datetime === value[0].datetime)) {
          // pause the checking while we get all messages
          this.newMessageCheckInterval.unsubscribe();

          let tempOffset = 0;
          let newMessages: ChatMessage[] = [];
          while (!cameAcrossAlreadyReceivedMessage) {
            let messages = await firstValueFrom(this.chatHttpService.getMessages(this.selectedTruckService.truckId, tempOffset, 10, false));
            tempOffset += 10;
            // for each message check if we have it already (means it's not new), and stop the loop

            messages.forEach(x => {
              if (!this.messages$.value.find(currentMessage => currentMessage.datetime === x.datetime) && !cameAcrossAlreadyReceivedMessage) {
                newMessages.push(x);
                if (this.messages$.value.length === 0) {
                  cameAcrossAlreadyReceivedMessage = true;
                }
              } else {
                cameAcrossAlreadyReceivedMessage = true;
              }
            });
          }
          const mappedNewMessages = this.mapMessages(newMessages);
          this.messages$.next([...this.messages$.value, ...mappedNewMessages.reverse()]);
          if(newMessages.length > 0) {
            this.newMessageCount$.next(newMessages.length);
            setTimeout(() => this.scrollToBottom());
          }

          // continue checking for new messages
          this.createNewMessageInterval();
        }
      });
  }

  //#endregion

  //#region Methods

  /**
   * Check for new messages every 5 seconds
   *
   * @returns void
   */
  private createNewMessageInterval(): void {
    this.newMessageCheckInterval = interval(5000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.subscribeToNewMessages();
    });
  }

  /**
   * Subscribe to Keyboard did show event, scroll chat when event triggered
   *
   * @returns void
   */
  private subscribeToKeyboard(): void {
    if (this.platformService.isMobileApp()) {
      Keyboard.addListener('keyboardDidShow', () => {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      });
    }
  }

  private mapMessages(messages: ChatMessageUI[]): ChatMessageUI[] {
    return messages.map(message => {
      const msg: ChatMessageUI = {
        ...message,
        showOriginal: false,
      };
      if (message.type === ChatMessageTypes.DOCUMENT && !message.fileType) {
        msg.fileType = 'application/pdf';
      }
      if (message.type === ChatMessageTypes.IMAGE && !message.fileType) {
        msg.fileType = 'image/jpg';
      }
      return msg;
    });
  }

  /**
   * Get all messages from Backend.
   *
   * @returns Promise<any>
   */
  private async getAllMessages(): Promise<any> {
    this.loadSavedState();
    const user = await this.authService.userData;
    this.user$.next(user === null ? { uid: this.storageService.get(StorageKeys.USER_ID) } : user);

    this.languageLoaded = this.translateService.currentLang;
    this.chatHttpService.getMessages(this.selectedTruckService.truckId, this.offset, this.limit)
      .pipe(first())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((messages: any[]) => {
        messages = messages.reverse();
        const currentMessages = this.messages$.value;
        const newMessages = [...messages, ...currentMessages];
        this.topDivForScroll = this.scrollContainer.nativeElement.scrollHeight;
        const mappedMessages = this.mapMessages(newMessages);
        this.messages$.next(mappedMessages);
        this.pushService.clearNotificationBadge();
        setTimeout(() => {
          this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight - this.topDivForScroll;

          this.hasMoreMessages = messages.length === this.limit;
          if (this.hasMoreMessages) {
            if (this.scrollContainer.nativeElement.scrollTop < 50) {
              this.getAllMessages();
              return;
            }
          }
          this.scrollContainer.nativeElement.addEventListener('scroll', () => {
            this.scrollListener();
          });
        });
      });
  }

  /**
   * Load more messages and scroll
   * remove listener for scroll if exists
   *
   * @returns void
   */
  private scrollListener(): void {
    this.isScrolledToBottom();
    if (this.scrollContainer.nativeElement.scrollTop < 50 && this.hasMoreMessages) {
      this.offset = this.offset + this.limit;
      this.getAllMessages();
      this.scrollContainer.nativeElement.removeAllListeners();
    }
  }

  /**
   * Load truck id from local storage, in case of page reload.
   *
   * @returns void
   */
  private loadSavedState(): void {
    const truckId = this.storageService.get(StorageKeys.TRUCK_ID);
    if (truckId) {
      this.selectedTruckService.truckId = truckId;
    }
  }

  //#endregion

  //#region UI responses

  /**
   * Open image preview
   *
   * @param imageMessage ChatMessage
   *
   * @returns void
   */
  public onImageClick(imageMessage: ChatMessage): void {
    if (imageMessage.image) {
      const dialogData: ImagePreviewFullScreenData = {
        src: 'data:image/png;base64,' + imageMessage.image
      };
      this.dialog.open(ImagePreviewFullscreenComponent, {
        data: dialogData,
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'full-preview',
      });
    } else {
      this.chatHttpService.getImageOrPdf(this.selectedTruckService.truckId, imageMessage.fileId).subscribe(async response => {
        imageMessage = await this.conversionService.convertBlobToBase64(imageMessage, response);
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Join Jitsi call
   *
   * @returns void
   */
  public async onJoinCall(message: ChatMessage): Promise<void> {
    if (message.type === this.ChatMessageTypes.CALL) {
      await this.jitsiService.loadScript();
      this.jitsiService.init(message.text);
    }
  }

  /**
   * Open pdf in browser tab or pdf- viewer
   *
   * @param message ChatMessage
   *
   * @returns void
   */
  public async onPdfClick(message: ChatMessage): Promise<void> {
    this.loaderService.showLoader(LoaderConsumersKeys.PDF_SERVICE);
    if (message.fileData) {
      if (this.platformService.isMobileApp()) {
        this.dialog.open(PdfViewerComponent, { data: message.fileData, height: '100%', width: '100%', maxWidth: '100vw', panelClass: 'full-preview' });
      } else {
        let url = URL.createObjectURL(message.fileData); // open it in browser tab
        window.open(url, '_blank');
      }
      this.loaderService.hideLoader(LoaderConsumersKeys.PDF_SERVICE);
    } else {  // if fileData missing, get pdf from API first
      this.chatHttpService.getImageOrPdf(this.selectedTruckService.truckId, message.fileId).pipe(catchError(() => { return of(null); })).subscribe(
        {
          next: async (value) => {
            if (!value) {
              return;
            }
            const index = this.messages$.value.findIndex(x => x === message);
            message.fileData = value;
            let array = this.messages$.value;
            array[index] = message;
            const mappedArray = this.mapMessages(array);
            this.messages$.next(mappedArray);
            this.onPdfClick(message);
          },
          error: () => { },
          complete: () => {
            this.loaderService.hideLoader(LoaderConsumersKeys.PDF_SERVICE);
          }
        }
      );
    }
  }

  //#endregion
}
