import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DataService} from "../../data-access";
import {SelectedTruckService} from "../asset";
import {NewChatMessageDto} from "@app-models";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _offlineTimeStamp$ = new BehaviorSubject<any>(null);
  public offlineTimeStamp$ = this._offlineTimeStamp$.asObservable();

  constructor(
    private readonly dataService: DataService,
    private readonly selectedTruckService: SelectedTruckService,
    private readonly translateService: TranslateService
  ) {}

  /**
   * Get file from blob name
   *
   * @param id AssetID
   * @param imageId ImageID
   * @returns Observable<base64>(blob)
   */
  public getImageOrPdf(id: string, imageId: number): Observable<Blob | null> {
    return this.dataService.files.getFileById(id, imageId);
  }

  public getMessages(id: string, offset: number, limit: number, loader: boolean = true) {
    return this.dataService.chatMessages.getMessages(id, this.translateService.currentLang ,{ pageNumber: offset, pageSize: limit }, loader);
  }

  public sendMessage(newMessage: NewChatMessageDto) {
    return this.dataService.chatMessages.add(this.selectedTruckService.truckId, newMessage);
  }

  public sendTextMessage(text: string) {
    return this.sendMessage({ text, language: this.translateService.currentLang });
  }

  public updateOfflineTimeStamp(): void {
    this._offlineTimeStamp$.next(new Date().getTime());
  }
}
