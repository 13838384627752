import { AdvancedHttpClient, Filter, Pagination, Sort, WebDataRepository } from '@libs/shared/data-access';
import { IEventRepository } from './IEventRepository';
import { EntityId } from '@libs/shared/types';
import { Observable } from 'rxjs';
import { CreateEventDto } from '@app-models';

export class EventRepository extends WebDataRepository<CreateEventDto> implements IEventRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'events');
  }

  public add(data: CreateEventDto): Observable<void> {
    return this.httpClient.post<void, CreateEventDto>({
      url: `${this.resource}/contents`,
      body: data,
    });
  }

  public getAll(filters?: Filter[], sort?: Sort<CreateEventDto>[], pagination?: Pagination, path?: string): Observable<CreateEventDto[]> {
    throw new Error('Method not supported.');
  }

  public getById(id: EntityId): Observable<CreateEventDto> {
    throw new Error('Method not supported.');
  }
}
