import { PhonebookEntry } from '@app-models';
import { FakeRepository } from '@libs/shared/data-access';
import { IPhonebookEntryRepository } from './IPhonebookEntryRepository';

const fakePhonebookEntries: PhonebookEntry[] = [];

export class FakePhonebookEntryRepository extends FakeRepository<PhonebookEntry> implements IPhonebookEntryRepository {
  constructor() {
    super(fakePhonebookEntries);
  }
}
