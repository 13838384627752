import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var JitsiMeetExternalAPI: any; // eslint-disable-line

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements AfterViewInit {

  private readonly domain: string = "jitsi.d3eif.com"; // For self hosted use your domain
  private options: any;
  private api: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialogRef<any>
  ) { }

  ngAfterViewInit(): void {
    this.startJitsi();
  }

  /**
   * Setup Jitsi config and start the meeting
   *
   * @returns void
   */
  private startJitsi(): void {
    this.options = {
      roomName: this.data.roomName,
      width: '100%',
      height: '100%',
      enableWelcomePage: false, // Enables the welcome page for guests, which allows them to configure their microphone, camera, and speaker settings before joining the conference
      enableClosePage: false,
      configOverwrite: {
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        MOBILE_APP_PROMO: false,
        disableDeepLinking: true,
        prejoinConfig: {
          enabled: false
        },
      },
      interfaceConfigOverwrite: {
        SHOW_CHROME_EXTENSION_BANNER: false,
        CLOSE_PAGE_GUEST_HINT: false
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.data.user.displayName,
        email: this.data.user.email
      }
    };

    this.api = new JitsiMeetExternalAPI(this.domain, this.options); // eslint-disable-line

    // Event handlers
    this.api.addEventListeners({
      readyToClose: this.handleClose
    });
  }

  /**
   * handle close page and leave meeting
   *
   * @returns void
   */
  private handleClose = (): void => {
    this.api.dispose();
    this.matDialog.close();
  };

}
