import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LegacyShipmentStopTaskStatuses } from '@app-enums';

@Component({
  selector: 'app-task-status-popup',
  templateUrl: './task-status-popup.component.html',
  styleUrls: ['./task-status-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusPopupComponent {

  public chosenStatus: LegacyShipmentStopTaskStatuses;
  public statuses = [
    { name: 'In progress', value: LegacyShipmentStopTaskStatuses.IN_PROGRESS },
    { name: 'Done', value: LegacyShipmentStopTaskStatuses.DONE },
    { name: 'Failed', value: LegacyShipmentStopTaskStatuses.FAILED },
    { name: 'Skipped', value: LegacyShipmentStopTaskStatuses.SKIPPED }
  ];

  constructor(
    private dialogRef: MatDialogRef<LegacyShipmentStopTaskStatuses>
  ) { }

  // #region UI Responses

  /**
   * Closes the popup and returns the selected status.
   *
   * @returns void
   */
  public onSave(): void {
    this.dialogRef.close(this.chosenStatus);
  }

  // #endregion

}
