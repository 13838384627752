import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SignatureFieldComponent} from '@app-shared-components';
import {BehaviorSubject, debounceTime} from 'rxjs';

@Component({
  selector: 'app-event-form-signature',
  standalone: true,
  imports: [CommonModule, SignatureFieldComponent],
  templateUrl: './event-form-signature.component.html',
  styleUrls: ['./event-form-signature.component.scss', '../event-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EventFormSignatureComponent),
    multi: true
  }]
})
export class EventFormSignatureComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() description: string;
  @Input() useDescAsLabel: boolean = false;
  @Input() required = false;

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  protected value: string;

  private onChange?: (val: string) => void;
  private onTouched?: () => void;

  protected signatureStream = new BehaviorSubject<string>('');

  protected onSignatureDraw(dataUrl: string) {
    this.signatureStream.next(dataUrl);
    this.onTouched?.();
  }

  protected onClear() {
    this.signatureStream.next('');
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
    this.signatureStream.pipe(debounceTime(400))
      .subscribe((value) => {
        this.onChange?.(value);
        this.value = value;
      });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
    if(this.onChange) {
      this.onChange(this.value);
    }
  }
}
