import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetName',
})
export class AssetNamePipe implements PipeTransform {
  transform(asset: any, fallback?: string): string {
    if (asset === undefined) {
      return 'Invalid Asset';
    }

    if (asset.displayName !== undefined && asset.displayName !== '') {
      return asset.displayName;
    }
    if (asset.licencePlate !== undefined && asset.licencePlate !== '') {
      return asset.licencePlate;
    }
    if (asset.suggestedName !== undefined && asset.suggestedName !== '') {
      return asset.suggestedName;
    }

    if (fallback !== undefined) {
      return asset[fallback];
    }
    return 'no name';
  }
}
