import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Paths } from '@app-enums';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {
    path: Paths.EMPTY,
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
