import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ImagePreviewFullScreenData {
  src: string;
}
@Component({
  selector: 'app-image-preview-fullscreen',
  templateUrl: './image-preview-fullscreen.component.html',
  styleUrls: ['./image-preview-fullscreen.component.scss'],
})
export class ImagePreviewFullscreenComponent {

  protected img: string;
  protected canDelete: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImagePreviewFullScreenData,
  ) {
    this.img = data.src;
  }

}
