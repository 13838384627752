import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterTypes } from '@app-enums';
import { ChatService, ConversionService, ToasterService } from '@app-services';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  public offlineDocumentsAndImages = [];

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private chatHttpService: ChatService,
    private conversionService: ConversionService,
  ) {}

  /**
   * Convert base64 to file and send to chat
   *
   * @param image string | { changingThisBreaksApplicationSecurity:string }
   * @param data string
   * @param noticeText string
   *
   * @return Promise<void>
   */
  public async uploadImage(image: any, data: string, noticeText: string): Promise<void> {
    let fileToSend = await this.conversionService.convertToFile(
      image.changingThisBreaksApplicationSecurity ?? image,
      this.getImageType(image.changingThisBreaksApplicationSecurity ?? image),
    );
    this.sendImageToChat(fileToSend, data, noticeText);
  }

  /**
   * Send image as message, set mode depending on what footer button user chose
   *
   * @param file File
   * @param mode string
   * @param noticeText string
   *
   * @return void
   */
  public sendImageToChat(file: File, mode: string, noticeText: string): void {
    this.chatHttpService.sendMessage({
      language: this.translateService.currentLang,
      file,
      imageType: mode,
      text: noticeText ?? '',
      fileName: file.name
    }).subscribe(
      {
        error: () => {
          this.toasterService.showMessage(
            this.translateService.instant('General.AnErrorOcurred'),
            ToasterTypes.ERROR
          );
        }
      }
    );
  }

  /**
   * Get type from base64
   *
   * @param base64String string
   *
   * @returns string
   */
  getImageType(base64String: string): string {
    const matches = base64String.match(/^data:(.*?);base64,/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return '';
  }
}
