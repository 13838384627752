import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent {

  @Input() url = '';
  @Output() closed = new EventEmitter<boolean>();

  constructor() { }

  closeEvent() {
    this.closed.emit(true);
  }

}
