import { AdvancedHttpClient, Filter, Pagination, Sort, WebDataRepository } from '@libs/shared/data-access';
import { Asset } from '@app-models';
import { IAssetRepository } from './IAssetRepository';
import { Observable } from 'rxjs';
import { EntityId } from '@libs/shared/types';

export class AssetRepository extends WebDataRepository<Asset> implements IAssetRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'drip-asset-list');
  }

  public getAll(filters?: Filter[], sort?: Sort<Asset>[], pagination?: Pagination, path?: string): Observable<Asset[]> {
    return super.getAll(filters, sort, pagination, 'assetListComplete');
  }

  public assignDriverToAsset(assetId: EntityId, pushToken?: string): Observable<void> {
    return this.httpClient.post<void, { assetId: string; pushToken?: string }>({
      url: `/d4d/users/loginToAsset`,
      body: { assetId, pushToken },
    });
  }
}
