import { ChangeDetectionStrategy, ChangeDetectorRef, Component, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { ChatPhoto } from '@app-models';
import { CameraService, ImageUploadService, PdfService, StorageService } from '@app-services';
import { BehaviorSubject } from 'rxjs';
import { ChatPhotoModes, CreatePdfModes, Paths, StorageKeys } from '@app-enums';
import { DocumentScanner, ResponseType } from 'capacitor-document-scanner';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-chat-preview',
  templateUrl: './chat-preview.component.html',
  styleUrls: ['./chat-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPreviewComponent {
  public showTakePhoto$ = new BehaviorSubject<boolean>(false);

  public messageType$ = new BehaviorSubject<string>(null);

  public images$ = new BehaviorSubject<ChatPhoto[]>(null);
  public noticeText: string;
  private imagesCounter: number;

  constructor(
    private cameraService: CameraService,
    private pdfService: PdfService,
    private imageUploadService: ImageUploadService,
    private storageService: StorageService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private imageCompression: NgxImageCompressService,
  ) {}

  ionViewWillEnter() {
    if (!this.cameraService.chatPhoto) {
      this.noticeText = '';
      this.imagesCounter = 0;
    } else {
      this.images$.next(this.cameraService.allChatPhotos);
    }
    this.messageType$.next(this.storageService.get(StorageKeys.CHAT_PHOTO_MODE));
  }

  ionViewDidEnter() {
    this.showTakePhoto$.next(this.isMobile());
  }

  //#region UI Responses

  /**
   * Go back to chat page.
   *
   * @returns void
   */
  public onGoBack(): void {
    this.cameraService.chatPhoto = null;
    this.cameraService.allChatPhotos = [];
    this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
  }

  /**
   * Send the message
   *
   * @returns Promise<void>
   */
  public async onSend(): Promise<void> {
    if (this.images$.value.length > 1) {
      this.sendMultipleImages();
    } else {
      this.sendImage();
    }
  }

  /**
   * Remove image from current images list.
   *
   * @param index number
   *
   * @returns void
   */
  public onDelete(index: number): void {
    const allImages = this.images$.value as ChatPhoto[];
    allImages.splice(index, 1);
    this.cameraService.allChatPhotos = allImages;
    this.images$.next(allImages);
  }

  /**
   * Edit photo in paint mode.
   *
   * @param image ChatPhoto
   *
   * @returns void
   */
  public onEdit(image: ChatPhoto): void {
    this.cameraService.chatPhoto = image;
    this.router.navigateByUrl(Paths.HOME_PAINT, { replaceUrl: true });
  }

  /**
   * Check camera permissions
   * Ask for permissions if not allowed already
   *
   * @returns void
   */
  public onCheckPermissions(): void {
    if (this.isIonicMobileAndroid()) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionCheck) => {
        if (permissionCheck.hasPermission) {
          this.takePhoto();
        } else {
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then((permissionRequest) => {
            if (permissionRequest.hasPermission) {
              this.takePhoto();
            }
          });
        }
      });
    } else {
      this.takePhoto();
    }
  }

  private isIonicMobileAndroid() {
    if (
      this.platform.is('android') &&
      this.platform.is('capacitor') &&
      this.platform.is('mobile') &&
      this.storageService.get(StorageKeys.CHAT_PHOTO_MODE) === ChatPhotoModes.INVOICE
    ) {
      return true;
    } else return false;
  }

  /**
   * Check if platform is mobile, if so button to Take a Photo will be shown
   *
   * @returns boolean
   */
  private isMobile(): boolean {
    return this.platform.is('mobile');
  }

  /**
   * Upload photo from gallery / device
   *
   * @returns void
   */
  public async onUploadImage(): Promise<void> {
    let image: string;
    try {
      image = await this.imageCompression.uploadAndGetImageWithMaxSize(5);
    } catch (e) {
      // uploadAndGetImageWithMaxSize returns the image as error, when failing to get a image of correct size
      image = e;
    }
    await this.handleCameraImage(image);
  }

  //#endregion

  //#region Handlers

  /**
   * Get photo from Camera, and add it to current images list.
   *
   * @returns void
   */
  public async takePhoto(): Promise<void> {
    if (this.isIonicMobileAndroid()) {
      const documents = await DocumentScanner.scanDocument({ responseType: ResponseType.Base64 });
      await Promise.all(
        documents.scannedImages.map((element) => {
          return this.handleCameraImage('data:image/jpeg;base64,' + element);
        }),
      );
    } else {
      const image = await this.cameraService.takePhoto();
      await this.handleCameraImage(image.dataUrl);
    }
  }

  /**
   * Convert Photo to ChatPhoto and update UI.
   *
   * @param imageDataUrl Image Data as DataURL string
   *
   * @returns void
   */
  private async handleCameraImage(imageDataUrl: string): Promise<void> {
    let compressedImage = await this.imageCompression.compressFile(imageDataUrl, DOC_ORIENTATION.Default, 80, 80, 1500, 1500);

    const chatPhoto = {} as ChatPhoto;
    let tempImage = new Image();
    chatPhoto.content = this.domSanitizer.bypassSecurityTrustResourceUrl(compressedImage);

    tempImage.onload = () => {
      chatPhoto.width = tempImage.width;
      chatPhoto.height = tempImage.height;
      chatPhoto.id = ++this.imagesCounter;
      const images = this.images$.value === null ? [] : this.images$.value;
      images.push(chatPhoto);
      this.cameraService.allChatPhotos = images;
      this.images$.next(images);
      this.cdr.detectChanges();
    };
    tempImage.src = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, chatPhoto.content);
  }

  /**
   * Handle user data nad send a message (PDF Upload)
   *
   * @param driver any
   * @param user firebase.User
   *
   * @returns Promise<void>
   */
  private async sendMultipleImages(): Promise<void> {
    const allImages = this.images$.value.map((x) => x.content);
    let pdf = await this.pdfService.createPdfFromImages(allImages);

    this.pdfService.uploadPdf(pdf.toString(), CreatePdfModes.DEFAULT, this.noticeText);

    this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
  }

  /**
   * Send a single image with specific mode
   *
   * @param driver any
   * @param user firebase.User
   *
   * @return Promise<void>
   */
  private async sendImage(): Promise<void> {
    const allImages = this.images$.value.map((x) => x.content);

    this.imageUploadService.uploadImage(allImages[0], this.storageService.get(StorageKeys.CHAT_PHOTO_MODE), this.noticeText);
    this.router.navigateByUrl(Paths.HOME_CHAT, { replaceUrl: true });
  }

  //#endregion
}
