import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import SignaturePad from 'signature_pad';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {ScreenOrientation} from '@capacitor/screen-orientation';

@Component({
  selector: 'app-signature-field',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signature-field.component.html',
  styleUrl: './signature-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureFieldComponent implements AfterViewInit {
  private readonly iconExitFullScreen = 'fa-compress';
  private readonly iconEnterFullScreen = 'fa-expand';
  private readonly iconRotateScreen = 'fa-arrows-rotate';

  @ViewChild('canvas') canvasEl!: ElementRef<HTMLCanvasElement>;

  @Input()
  set signature(data: string | undefined) {
    this.signatureData = data;
    if(this.signaturePad) {
      this.signaturePad.fromDataURL(data);
    }
  }
  @Output() signatureDraw = new EventEmitter<string>();
  @Output() signatureCleared = new EventEmitter<boolean>();

  signaturePad!: SignaturePad;
  private signatureData?: string;
  protected fullScreen$ = new BehaviorSubject<boolean>(false);
  protected sizeToggleIcon$ = new BehaviorSubject<string>(this.iconEnterFullScreen);

  protected readonly isCapacitor: boolean;

  constructor(private readonly platform: Platform) {
    this.isCapacitor = this.platform.is('capacitor');
    if(this.isCapacitor) {
      this.sizeToggleIcon$.next(this.iconRotateScreen);
    }
  }

  public ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
      backgroundColor: '#fff',
    });
    window.addEventListener('resize', () => this.resizeCanvas());
    this.resizeCanvas();
    this.signaturePad.addEventListener('afterUpdateStroke', () => {
      this.signatureData = this.signaturePad.toDataURL();
      this.signatureDraw.emit(this.signatureData);
    });
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const smallerSide = Math.min(vh, vw);
    const biggerSide = Math.max(vh, vw);
    this.canvasEl.nativeElement.style.setProperty('aspect-ratio', `${biggerSide} / ${smallerSide}`);
  }

  private resizeCanvas() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvasEl.nativeElement.width = this.canvasEl.nativeElement.offsetWidth * ratio;
    this.canvasEl.nativeElement.height = this.canvasEl.nativeElement.offsetHeight * ratio;
    this.canvasEl.nativeElement.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear();
    if(this.signatureData) {
      this.signaturePad.fromDataURL(this.signatureData);
    }
  }

  protected toggleExpand() {
    this.fullScreen$.next(!this.fullScreen$.value);
    if(this.isCapacitor) {
      ScreenOrientation.lock({
        orientation: this.fullScreen$.value ? 'landscape' : 'portrait',
      });
    } else {
      if(this.fullScreen$.value) {
        this.sizeToggleIcon$.next(this.iconExitFullScreen);
      } else {
        this.sizeToggleIcon$.next(this.iconEnterFullScreen);
      }
    }
    setTimeout(() => this.resizeCanvas(), 50);
  }

  protected clearSignature() {
    this.signaturePad.clear();
    this.signatureData = undefined;
    this.signatureCleared.emit(true);
  }
}
