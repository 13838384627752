import { AdvancedHttpClient, WebDataRepository } from '@libs/shared/data-access';
import { IFileRepository } from './IFileRepository';
import {EntityId, EntityIndex} from '@libs/shared/types';
import { Observable } from 'rxjs';

export class FileRepository extends WebDataRepository<Blob> implements IFileRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'users/chats');
  }

  public getFileById(id: EntityId, fileId: EntityIndex): Observable<Blob> {
    return this.httpClient.downloadFile(`${this.resource}/${id}/${fileId}`, {
      referenceType: 'asset'
    });
  }
}
