import {
  LegacyShipmentStopTaskStatuses,
  ShipmentStopTaskStatus,
  ShipmentStopTaskType,
} from '@app-enums';
import {LegacyShipmentStopTimeWindow} from "./shipment-stop.model";
import { EntityId } from '@libs/shared/types';

export interface LegacyShipmentStopTask {
  consignmentExternalId: EntityId;
  consignmentId?: string;
  consignmentLoad: LegacyLoad;
  companyId: string,
  load: LegacyLoad,
  endDateTime?: number;
  startDateTime?: number;
  status?: LegacyShipmentStopTaskStatuses;
  statusTimestamp?: number;
  statusUpdateTimestamp?: number;
  statusUpdateUserId?: EntityId;
  statusUpdateUserType?: string; //todo enum?
  taskId?: EntityId;
  type?: ShipmentStopTaskType;
  data?: any;
  id?: string;
  timeWindow?: LegacyShipmentStopTimeWindow;
  documentType?: string;
}

export interface LegacyShipmentStopTaskUI extends LegacyShipmentStopTask {
  expanded?: boolean;
}

export interface LegacyLoad {
  amount: number;
  name: string;
  posArt: string;
  weight_kg: number;
}

export interface DatetimeFCM {
  _nanoseconds: number;
  _seconds: number;
}

export interface ShipmentStopTask {
  consignmentId: EntityId;
  endDateTime?: number;
  startDateTime?: number;
  status: ShipmentStopTaskStatus;
  id: EntityId;
  type: ShipmentStopTaskType;

  messages: unknown[];
  finishedDatetime?: DatetimeFCM;
}
