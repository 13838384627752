import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {AuthService, LanguageService, NetworkService, PushService, StorageService} from '@app-services';
import {NavigationEnd, Router} from '@angular/router';
import {Meta} from '@angular/platform-browser';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Paths, StorageKeys} from '@app-enums';
import {Language} from '@libs/shared/environment';
import {User} from '@libs/shared/models';
import {MatDialog} from '@angular/material/dialog';
import {NotificationPopupComponent} from '../notification-popup/notification-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  protected readonly paths = Paths;
  //#region Class properties

  protected readonly showChatButton$ = new BehaviorSubject<boolean>(null);
  protected readonly showNoNotifications$ = new BehaviorSubject<boolean>(null);
  protected readonly appVersion$ = new BehaviorSubject<string>(null);
  protected readonly allLanguages$ = new BehaviorSubject<readonly Language[]>(null);
  protected readonly currentLanguage$ = new BehaviorSubject<Language>(null);
  protected readonly networkStatus$ = new BehaviorSubject<boolean>(null);
  protected readonly user$ = new BehaviorSubject<User>(null);

  //#endregion

  constructor(
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly meta: Meta,
    private readonly networkService: NetworkService,
    private readonly storageService: StorageService,
    private readonly pushService: PushService,
    private readonly dialog: MatDialog,
  ) {
  }

  public async ngOnInit() {
    await this.setUserName();
    this.initLanguage();
    this.subscribeToUrlChanges();
    this.initAppVersion();
    this.listenToNetworkChanges();
    this.listenToNotificationStatus();
  }

  //#region UI Responses

  /**
   * Change language based on language two-characters code.
   *
   * @param languageCode string
   *
   * @returns void
   */
  protected onChangeLanguage(languageCode: string): void {
    this.languageService.setLanguage(languageCode);
    this.currentLanguage$.next(this.languageService.getCurrentLanguage());
  }

  /**
   * Go to desired path.
   *
   * @param path string
   *
   * @returns void
   */
  protected onGoToPath(path: string): void {
    this.router.navigateByUrl(path, {replaceUrl: true});
  }

  /**
   * Logout user
   *
   * @returns void
   */
  protected onLogout(): void {
    this.authService.logout(true);
  }

  // #endregion

  //#region Methods

  /**
   * Get current user's name
   *
   * @returns Promise<void>
   */
  private async setUserName(): Promise<void> {
    this.authService.user$.subscribe((user) => {
      this.user$.next(user);
      if (!this.user$.value) {
        this.user$.next(this.storageService.get(StorageKeys.USER));
      }
    });
  }

  /**
   * Initialize language settings.
   *
   * @returns void
   */
  private initLanguage(): void {
    this.languageService.initDefaultLanguage();
    this.allLanguages$.next(this.languageService.availableLanguages);
    this.languageService.currentLanguage$.subscribe((lang) => this.currentLanguage$.next(lang));
  }

  /**
   * Displays current app version,
   * based on index.html tag
   *
   * @returns void
   */
  private initAppVersion(): void {
    const view = this.meta.getTag('name=version');
    this.appVersion$.next(view.content);
  }

  //#endregion

  //#region Subscriptions

  /**
   * Listen to network changes to display offline banner
   *
   * @returns void
   */
  private listenToNetworkChanges(): void {
    this.networkService.networkStatus$.pipe(distinctUntilChanged()).subscribe((status) => {
      this.networkStatus$.next(status);
    });
  }

  /**
   * Sets up the observable to listen for Notification permission status
   * @private
   */
  private listenToNotificationStatus() {
    combineLatest([this.networkService.networkStatus$, this.showChatButton$, this.pushService.notificationDenied$])
      .subscribe(([network, chat, notification]) => {
        this.showNoNotifications$.next(network && !chat && notification);
      });
  }

  /**
   * Subscribe to URL changes in order to check
   * if we need to display the Chat icon.
   *
   * @returns void
   */
  private subscribeToUrlChanges(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((urlParams: NavigationEnd) => {
      this.handleUrlChanges(urlParams);
    });
  }

  //#endregion

  //#region Handlers

  /**
   * Update UI based on UI changes.
   * This will display "chat" icon in some cases.
   *
   * @param urlParams NavigationEnd
   *
   * @returns void
   */
  private handleUrlChanges(urlParams: NavigationEnd): void {
    const url = urlParams.url as Paths;
    const showChatIconUrls = [
      Paths.ROOT_TRIP,
      Paths.ROOT_HOME_TRIPS_AND_SHIPMENTS,
      Paths.ROOT_HOME_TRIP_STOPS,
      Paths.ROOT_HOME_TRIP_STOPS_TASKS,
      Paths.ROOT_HOME_SHIPMENT_STOPS,
      Paths.ROOT_HOME_SHIPMENT_STOPS_TASKS,
    ];
    this.showChatButton$.next(showChatIconUrls.includes(url));
  }

  protected showNotificationPopup() {
    this.dialog.open(NotificationPopupComponent, {
      width: '80%',
      maxWidth: '450px'
    });
  }

  //#endregion
}
