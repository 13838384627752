import { Observable, of } from 'rxjs';
import {IUserRepository, FakeRepository, Filter, Sort, Pagination} from '@libs/shared/data-access';
import { EntityId } from '@libs/shared/types';
import { RefreshTokenDto, User } from '@libs/shared/models';

const fakeUsers: User[] = [
  {
    uid: '1',
    displayName: 'John Doe',
    accessToken: 'abc123',
    refreshToken: 'def456'
  }
];

export class FakeUserRepository extends FakeRepository<User> implements IUserRepository {
  constructor() {
    super(fakeUsers);
  }

  public override getAll(filters?: Filter[], sort?: Sort<User>[], pagination?: Pagination): Observable<User[]> {
    throw new Error('Method not supported.');
  }

  public override getById(id: EntityId): Observable<User> {
    throw new Error('Method not supported.');
  }

  public login(email: string, password: string): Observable<User> {
    return this.delayResponse(of(fakeUsers[0]));
  }

  public logout(): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public refreshTokens(refreshToken: string): Observable<RefreshTokenDto> {
    return this.delayResponse(of({ refreshToken: 'abc123', accessToken: 'def456' }));
  }
}
