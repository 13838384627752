import { CreatePdfModes } from '@app-enums';

export interface PdfUploadData {
  tripId?: string;
  tripStopId: string;
  shipmentId: string;
  shipmentStopId: string;
  userUid?: string;
  userEmail?: string;
  userName?: string;
  truckId?: string;
  mode: CreatePdfModes;
}
