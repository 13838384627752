import { Injectable } from '@angular/core';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { LoaderConsumersKeys, Paths, StorageKeys } from '@app-enums';
import { DataService, LoaderService, StorageService } from '@app-services';
import { Router } from '@angular/router';
import { User } from '@libs/shared/models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn$ = new BehaviorSubject<boolean>(null);
  private userIsLoggedIn: boolean;
  public user$ = new BehaviorSubject<User>(null);

  private refreshLoading = false;

  constructor(
    private storageService: StorageService,
    private dataService: DataService,
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  public get userData(): any {
    return this.user$.value;
  }

  public set newUserData(data: User) {
    this.user$.next(data);
    this.storageService.set(StorageKeys.USER, data);
  }

  public get isLoggedIn(): boolean {
    this.userIsLoggedIn = this.storageService.get(StorageKeys.LOGGED_IN);
    return this.userIsLoggedIn;
  }
  public set isLoggedIn(value: boolean) {
    this.storageService.set(StorageKeys.LOGGED_IN, value);
    this.loggedIn$.next(value);
    this.userIsLoggedIn = value;
  }

  /**
   * Login to Firestore with email and password (pin code) provided.
   *
   * @param email string
   *
   * @param password
   *
   * @returns Promise<any>
   */
  public login(email: string, password: string): Observable<User> {
    return this.dataService.users.login(email.toLowerCase(), password);
  }

  /**
   * Refresh token
   *
   * @returns void
   */
  public refreshToken(): void {
    if(this.refreshLoading) {
      return;
    }
    if (this.storageService.get(StorageKeys.REFRESH_TOKEN)) {
      this.refreshLoading = true;
      this.dataService.users.refreshTokens(this.storageService.get(StorageKeys.REFRESH_TOKEN)).subscribe({
        next: (value) => {
          const storedUserData: User = this.storageService.get(StorageKeys.USER);
          const refreshUser: User = {
            refreshToken: value.refreshToken,
            accessToken: value.accessToken,
            uid: storedUserData?.uid ?? '',
            displayName: storedUserData?.displayName ?? ''
          };
          this.setLoggedInState(refreshUser);
        },
        error: () => {
          this.logout();
        },
        complete: () => {
          this.refreshLoading = false;
        }
      });
    } else {
      this.isLoggedIn = false;
    }
  }

  public setLoggedInState(user: User) {
    this.storageService.set(StorageKeys.TOKEN, user.accessToken);
    this.storageService.set(StorageKeys.LOGGED_IN, true);
    this.storageService.set(StorageKeys.USER_ID, user.uid);
    this.storageService.set(StorageKeys.REFRESH_TOKEN, user.refreshToken);
    this.isLoggedIn = true;
    this.loaderService.hideLoader(LoaderConsumersKeys.LOGIN);
    this.newUserData = user;
  }

  /**
   * Makes the Logout http request.
   *
   * @returns void
   */
  public logout(force = false): void {
    if(!this.loggedIn$.value && !force) {
      this.logoutCleanup();
      return;
    }
    this.dataService.users
      .logout()
      .pipe(first())
      .subscribe({
        next: () => {
          this.logoutCleanup();
        },
        error: () => {
          this.logoutCleanup();
        },
      });
  }

  /**
   * Clear all items from storage that could affect
   * the app flow during next login.
   *
   * @returns void
   */
  public clearStorageBeforeLogout(): void {
    this.storageService.removeMultiple([
      StorageKeys.TRUCK_ID,
      StorageKeys.TRUCK_DISPLAY_NAME,
      StorageKeys.TRAILER_ID,
      StorageKeys.SHIPMENT_ID,
      StorageKeys.SHIPMENT_STOP_ID,
      StorageKeys.CHAT_PHOTO_MODE,
      StorageKeys.USER_ID,
      StorageKeys.REFRESH_TOKEN,
      StorageKeys.TOKEN,
    ]);
    this.storageService.set(StorageKeys.LOGGED_IN, false);
  }

  private logoutCleanup() {
    this.isLoggedIn = false;
    this.clearStorageBeforeLogout();
    this.router.navigateByUrl(Paths.ROOT_AUTH, { replaceUrl: true });
    this.user$.next(null);
    this.storageService.remove(StorageKeys.USER);
    this.loaderService.hideLoader(LoaderConsumersKeys.HEADER);
  }
}
