import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {AssetSelectService, PushService, SelectedTruckService, ShipmentService, StorageService} from '@app-services';
import { StorageKeys } from '@app-enums';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {Platform} from '@ionic/angular';
import {App} from '@capacitor/app';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit {

  protected readonly thereAreShipments$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly storageService: StorageService,
    private readonly shipmentService: ShipmentService,
    private readonly pushService: PushService,
    private readonly assetSelectService: AssetSelectService,
    private readonly truckSelectedService: SelectedTruckService,
    private readonly platform: Platform,
  ) { }

  ngOnInit() {
    this.checkIfThereAreShipments();
    this.getPushToken();
    this.setupAppStateListener();
  }

  private setupAppStateListener() {
    if(!this.platform.is('capacitor')) {
      return;
    }
    App.addListener('resume', () => {
      this.getPushToken();
    });
  }

  private getPushToken() {
    if(!this.pushService.hasRegisteredPush) {
      const currentAsset = this.truckSelectedService.assetID;
      firstValueFrom(this.assetSelectService.loginToAsset(currentAsset));
    }
  }

  //#region Methods

  /**
   * Check if there are shipments for the current Truck Id,
   * in order to update UI elements dimensions.
   *
   * @returns void
   */
  private checkIfThereAreShipments(): void {
    const truckId = this.storageService.get(StorageKeys.TRUCK_ID);
    if (truckId) {
      this.shipmentService.hasShipments(truckId).subscribe(hasShipments => {
        this.thereAreShipments$.next(hasShipments);
      });
    }
  }

  //#endregion
}
