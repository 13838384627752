import { DataService } from './DataService';
import { FakeShipmentRepository } from './repositories/shipments/FakeShipmentRepository';
import { FakeAssetRepository } from './repositories/assets/FakeAssetRepository';
import { FakeEventRepository } from './repositories/events/FakeEventRepository';
import { FakePhonebookEntryRepository } from './repositories/phonebook-entries/FakePhonebookEntryRepository';
import { FakeChatMessageRepository } from './repositories/chats/FakeChatMessageRepository';
import { FakeFileRepository } from './repositories/files/FakeFileRepository';
import { FakeEventTypeRepository } from './repositories/event-types/FakeEventTypeRepository';
import {FakeUserRepository} from './repositories/user/FakeUserRepository';

export class FakeApiDataService extends DataService {
  constructor() {
    super();

    this.shipments = new FakeShipmentRepository();
    this.assets = new FakeAssetRepository();
    this.events = new FakeEventRepository();
    this.eventTypes = new FakeEventTypeRepository();
    this.chatMessages = new FakeChatMessageRepository();
    this.files = new FakeFileRepository();
    this.phonebookEntries = new FakePhonebookEntryRepository();
    this.users = new FakeUserRepository();
  }
}
