import {Inject, Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {PermissionStatus, PushNotifications} from '@capacitor/push-notifications';
import {Platform} from '@ionic/angular';
import {BehaviorSubject, from, interval, Observable, of, switchMap, tap} from 'rxjs';
import {IMobileAppEnvironment} from '@app-environments/IMobileAppEnvironment';
import {ENVIRONMENT, EnvironmentStage} from '@libs/shared/environment';
import {SwPush} from '@angular/service-worker';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Paths, StorageKeys} from '@app-enums';
import {TranslateService} from '@ngx-translate/core';
import {AndroidSettings, IOSSettings, NativeSettings} from 'capacitor-native-settings';
import {StorageService} from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class PushService {

  private notificationDeniedSrc$ = new BehaviorSubject(false);
  public notificationDenied$ = this.notificationDeniedSrc$.asObservable();

  private token = null;

  public get hasRegisteredPush() {
    return this.token !== null;
  }

  constructor(
    private readonly afMessaging: AngularFireMessaging,
    private readonly platform: Platform,
    private readonly sw: SwPush,
    private readonly toast: MatSnackBar,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly storageService: StorageService,
    @Inject(ENVIRONMENT) private readonly environment: IMobileAppEnvironment
  ) {
    this.listenForPushPermissionChange();
  }

  /**
   * On Native platform opens the Device Settings for Push Notifications
   */
  public goToSetting() {
    if (!this.platform.is('capacitor')) {
      return;
    }
    NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App
    });
  }

  /**
   * Get the FCM Push notification token
   */
  public getToken(): Observable<string> {
    if (this.platform.is('capacitor')) {
      return this.getTokenCapacitor();
    }
    return this.getTokenPWA();
  }

  /**
   * Checks whether the current Platform supports the use of Notifications
   */
  public notificationAvailable(): boolean {
    if (this.platform.is('capacitor')) {
      return true;
    }
    return typeof Notification === 'function';
  }

  /**
   * Clears the notification badge on the app icon
   */
  public clearNotificationBadge() {
    if (this.platform.is('capacitor')) {
      PushNotifications.removeAllDeliveredNotifications();
    }
  }

  /**
   * Periodically checks Push Notification permission, in case the permissions have been changed in the settings
   * @private
   */
  private listenForPushPermissionChange() {
    if(this.platform.is('capacitor')) {
      interval(5_000)
        .pipe(switchMap(() => from(PushNotifications.checkPermissions())))
        .subscribe(permissions => {
          const checked: boolean = this.storageService.get(StorageKeys.PUSH_NOTIFICATION_ASKED);
          if(!checked) {
            return;
          }
          this.notificationDeniedSrc$.next(permissions.receive !== 'granted');
        });
    }
  }

  /**
   * Fetches the FCM Push token in native app context
   * @private
   */
  private getTokenCapacitor(): Observable<string> {
    return new Observable<PermissionStatus>((observer) => {
      const checked: boolean = this.storageService.get(StorageKeys.PUSH_NOTIFICATION_ASKED);
      let permPromise: Promise<PermissionStatus>;
      if (checked) {
        permPromise = PushNotifications.checkPermissions();
      } else {
        permPromise = PushNotifications.requestPermissions();
      }
      this.storageService.set(StorageKeys.PUSH_NOTIFICATION_ASKED, true);
      permPromise
        .then(permission => {
          if(permission.receive === 'prompt') {
            return PushNotifications.requestPermissions();
          }
          return Promise.resolve(permission);
        })
        .then(permission => observer.next(permission));
      permPromise.catch(err => observer.error(err));
    })
      .pipe(
        switchMap(permission => {
          return new Observable<PermissionStatus>(observer => {
            PushNotifications.removeAllListeners()
              .finally(() => observer.next(permission));
          });
        }),
        switchMap(permission => {
          if (permission.receive !== 'granted') {
            this.notificationDeniedSrc$.next(true);
            return of('');
          }
          PushNotifications.createChannel({
            id: 'd4d-chat',
            name: this.translate.instant('General.Notifications.ChannelName'),
            importance: 5,
            sound: 'default',
            lights: true,
            lightColor: '#d22454',
            vibration: true,
            visibility: 1,
            description: this.translate.instant('General.Notifications.ChannelDesc')
          });
          return new Observable<string>(observer => {
            PushNotifications.addListener('registration', (token) => {
              this.token = token.value;
              this.notificationDeniedSrc$.next(false);
              observer.next(token.value);
              this.registerPushListenersCapacitor();
            });
            PushNotifications.addListener('registrationError', (error) => {
              this.notificationDeniedSrc$.next(false);
              observer.error(error.error);
            });
            PushNotifications.register();
          });
        })
      );
  }

  /**
   * Listens to Push notifications in the Web version of the App
   * @private
   */
  private registerPushListenersPWA() {
    this.afMessaging.messages.subscribe(message => {
      if (message.notification) {
        const toastRef = this.toast.open(message.notification.body, 'Chat', {
          duration: 2500,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        toastRef.onAction().subscribe(action => {
          this.router.navigateByUrl(`${Paths.CHAT}`);
        });
      }
    });
  }

  /**
   * Listens for Push notifications in native version of the App
   * @private
   */
  private registerPushListenersCapacitor() {
    PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
      this.router.navigateByUrl(`${Paths.CHAT}`);
    });
  }

  /**
   * Gets FCM Token in Web version of the App
   * @private
   */
  private getTokenPWA(): Observable<string> {
    if (this.environment.stage === EnvironmentStage.LOCAL || !this.notificationAvailable() || !this.sw.isEnabled) {
      this.token = '';
      this.notificationDeniedSrc$.next(false);
      return of('');
    }
    return from(Notification.requestPermission())
      .pipe(
        switchMap(permission => {
          if (permission !== 'granted') {
            this.notificationDeniedSrc$.next(true);
            return of('');
          }
          this.notificationDeniedSrc$.next(false);
          return this.afMessaging.getToken;
        }),
        tap(token => {
          if (token) {
            this.token = token;
            this.registerPushListenersPWA();
          }
        })
      );
  }

}
