import { FakeRepository } from '@libs/shared/data-access';
import { IFileRepository } from './IFileRepository';
import {EntityId, EntityIndex} from "@libs/shared/types";
import {Observable} from "rxjs";

const fakeFiles: Blob[] = [];

export class FakeFileRepository extends FakeRepository<Blob> implements IFileRepository {
  constructor() {
    super(fakeFiles);
  }

  public getFileById(id: EntityId, fileId: EntityIndex): Observable<Blob> {
    return this.getById(id);
  }
}
