import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StorageService } from '@app-services';
import { StorageKeys } from '@app-enums';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedTruckService {

  public truckId: string;
  public trailerId: string;
  public licencePlate: string;

  public get assetID(): string {
    return this.truckId ?? this.storageService.get(StorageKeys.TRUCK_ID);
  }

  constructor(
    private afs: AngularFirestore,
    private storageService: StorageService,
  ) { }

  /**
   * Save asset ID into storage.
   *
   * @param assetId string
   *
   * @returns void
   */
  public saveAssetID(assetId: string): void {
    this.truckId = assetId;
    this.storageService.set(StorageKeys.TRUCK_ID, assetId);
  }

  /**
   * Get Licence plate from FireStore, based on Asset ID.
   *
   * @param assetId string
   *
   * @returns Observable<any>
   */
  public getDisplayNameByAssetId(assetId: string): Observable<any> {
    return this.afs.doc(`/assets/${assetId}`).valueChanges();
  }

  /**
   * Get Asset info from FireStore, based on Asset ID.
   *
   * @param assetId string
   *
   * @returns Observable<any>
   */
  public getAssetDetailsById(assetId: string): Observable<any> {
    return this.afs.doc(`/assets/${assetId}/`).valueChanges();
  }
}
