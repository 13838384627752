import { Pipe, PipeTransform } from '@angular/core';
import { TripStopTask } from '@app-models';

@Pipe({
  name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {

  transform(task: TripStopTask, ...args: unknown[]): unknown {
    if (task.skipped) {
      return 'Skipped';
    } else if (task.failed === true) {
      return 'Failed';
    } else if (task.finished === true) {
      return 'Finished';
    } else if (task.started === true) {
      return 'Started';
    } else {
      return 'Undefined';
    }
  }

}
