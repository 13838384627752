import { Injectable, NgZone } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app-services';
import { LoaderConsumersKeys } from '@app-enums';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    private readonly interceptExceptions: string[] = ['binary/download/', "binary/uploadImageOrPDF"];
    private readonly chatEndpoint = 'd4d/users/chats';
    private readonly chatOffsetSkip = '0';
    private readonly chatLimitSkip = '1';

    constructor(
        private loaderService: LoaderService,
        private zone: NgZone
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let skipInterception = false;

        this.zone.run(() => {
            this.interceptExceptions.forEach(x => {
                if (req.url.includes(x)) {
                    skipInterception = true;
                }

                // don't display loader for CheckForNewMessages Poll-ing
                if (
                    req.headers.get('loader') === 'false'
                ) {
                    skipInterception = true;
                }
            });
            if (!skipInterception) {
                this.loaderService.showLoader(LoaderConsumersKeys.HTTP);
            }
        });
        if (skipInterception) {
            return next.handle(req);
        }
        return next.handle(req).pipe(
            finalize(() => {
                this.zone.run(() => {
                    this.loaderService.hideLoader(LoaderConsumersKeys.HTTP);
                });
            })
        );
    }
}
