import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@app-services';
import { EntityId } from '@libs/shared/types';
import { CreateEventDto, EventType, EventTypeFormField } from '@app-models';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  constructor(
    protected dataService: DataService,
    private formBuilder: FormBuilder,
    private translation: TranslateService,
  ) {}

  public getAllDripEventTypes(): Observable<EventType[]> {
    return this.dataService.eventTypes.getAll();
  }

  public getEventTypeById(eventTypeId: EntityId | number): Observable<EventType> {
    return this.dataService.eventTypes.getById(String(eventTypeId));
  }

  /**
   * Create dynamic form based on passed fields.
   *
   * @param fields EventHandlingFormField[]
   *
   * @returns FormGroup
   */
  public createEventDynamicForm(fields: EventTypeFormField[]): FormGroup {
    let eventDynamicForm: FormGroup = this.formBuilder.group({});

    fields.forEach((field) => {
      eventDynamicForm.addControl(
        field.name,
        new FormControl('', field.required ? [Validators.required] : []),
      );
    });
    eventDynamicForm.addControl(
      'visibleForCustomer', new FormControl(false, [])
    );

    return eventDynamicForm;
  }

  public addNewEvent(data: CreateEventDto) {
    return this.dataService.events.add(data);
  }

  /**
   * Returns default event form fields.
   *
   * @returns EventTypeFormField[]
   *
   * TOTO: check if we can get fields form backend later
   */
  public defaultFormFields(): EventTypeFormField[] {
    return [
      {
        name: 'description',
        description: this.translation.instant('eventHandler.labels.description'),
        type: 'textarea',
        required: true,
        useDescAsLabel: true
      },
      {
        name: 'client',
        description: this.translation.instant('eventHandler.labels.client'),
        type: 'text',
        required: false,
        useDescAsLabel: true
      },
      {
        name: 'reference',
        description: this.translation.instant('eventHandler.labels.reference'),
        type: 'text',
        required: false,
        useDescAsLabel: true
      }
    ] as EventTypeFormField[];
  }
}
