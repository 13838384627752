import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ShipmentService } from '@app-services';
import { Paths } from '@app-enums';
import { LegacyShipment } from '@app-models';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-show-my-trips',
  templateUrl: './chat-show-my-trips.component.html',
  styleUrls: ['./chat-show-my-trips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatShowMyTripsComponent implements OnInit {

  public trips$ = new BehaviorSubject<boolean>(false);
  public shipments$ = new BehaviorSubject<boolean>(false);

  constructor(
    private shipmentService: ShipmentService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getAllShipments();
  }

  //#region UI Responses

  public onGoToTripsPage(): void {
    this.router.navigateByUrl(Paths.HOME_TRIPS_AND_SHIPMENTS, { replaceUrl: true });
  }

  //#endregion

  //#region Methods

  /**
   * Get all shipments from AFS.
   *
   * @returns void
   */
  private getAllShipments(): void {
    this.shipmentService.shipments$.subscribe(shipments => {
      this.handleShipments(shipments);
      shipments.forEach(shipment => this.shipmentService.onShipmentUpdate(shipment.shipmentId, this.handleShipments));
    });
  }

  //#endregion

  //#region Handlers

  /**
   * Update UI if there are shipments.
   *
   * @param shipments Shipment[]
   *
   * @returns void
   */
  private handleShipments = (shipments: LegacyShipment[]): void => {
    if (shipments && shipments.length > 0) {
      this.shipments$.next(true);
    } else {
      this.shipments$.next(false);
    }
  };

  //#endregion

}
