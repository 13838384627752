import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { PlatformService } from '@app-services';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { ENVIRONMENT, EnvironmentStage, IEnvironment } from '@libs/shared/environment';

const initSentry = (p: PlatformService, environment: IEnvironment) => () => {
  if(environment.stage === EnvironmentStage.LOCAL) {
    return;
  }
  let dsn = '';
  if (p.isMobileApp()) {
    dsn = 'https://f5bbfc447dce45cb88e4e7d8a6205ea2@o1151456.ingest.sentry.io/6228153';
  } else {
    dsn = 'https://9b5b1b4df68b432fb8bbf016b43a7465@o1151456.ingest.sentry.io/4504966763511808';
  }
  Sentry.init({
    dsn: dsn,
    environment: environment.stage === EnvironmentStage.PRODUCTION ? 'production' : 'development',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://dashboard-test-ba888.web.app', 'https://d3eif-dashboard.web.app'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
};

export const sentryProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: initSentry,
    deps: [PlatformService, ENVIRONMENT],
    multi: true,
  },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
