import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@app-services';
import { StorageKeys } from '@app-enums';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ENVIRONMENT, IEnvironment, Language } from '@libs/shared/environment';
import { Utils } from '@libs/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public readonly availableLanguages: readonly Language[] = Utils.clone(this.environment.i18n.availableLanguages);
  public readonly currentLanguage$ = new BehaviorSubject<Language>(this.availableLanguages[0]);

  private readonly defaultLanguage = this.environment.i18n.defaultLanguageCode;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: IEnvironment,
    private readonly translate: TranslateService,
    private readonly storageService: StorageService,
  ) {}

  /**
   * Get previously used language saved in storage.
   * If no saved language, use English as a default.
   *
   * @returns void
   */
  public initDefaultLanguage(): void {
    const savedLangCode = this.storageService.get(StorageKeys.LANGUAGE);
    this.translate.setDefaultLang(this.defaultLanguage);
    if (savedLangCode) {
      this.setLanguage(savedLangCode);
    } else {
      this.setLanguage(this.defaultLanguage);
    }
  }

  /**
   * Set new language by param 'lang', which is
   * two-characters long language code (en, de, fr...).
   *
   * @param lang string
   *
   * @returns void
   */
  public setLanguage(lang: string): void {
    if (!this.availableLanguages.find((x) => x.code === lang)) {
      lang = this.environment.i18n.defaultLanguageCode;
    }
    this.translate.use(lang);
    this.storageService.set(StorageKeys.LANGUAGE, lang);

    const language = this.availableLanguages.find((x) => x.code === lang);
    if (language) {
      language.selected = true;
      this.availableLanguages.filter((x) => x.code !== lang).forEach((x) => (x.selected = false));
      this.currentLanguage$.next(language);
    }
  }

  /**
   * Create and return simple form for language selection with current language value
   *
   * @returns FormGroup
   */
  public getLanguageForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      selectedLanguage: new UntypedFormControl(this.getCurrentLanguage(), Validators.required),
    });
  }

  /**
   * Returns currently selected language.
   *
   * @returns Language
   */
  public getCurrentLanguage(): Language {
    const current = this.availableLanguages.find((x) => x.selected);
    return current ? current : this.availableLanguages[0];
  }
}
