import { LegacyShipmentStop, ShipmentStatus, ShipmentStop } from '@app-models';
import { ShipmentDocument } from './shipment-document';
import { ShipmentStatusHistory } from './shipment-status-history';
import { ShipmentTemperatureControl } from './shipment-temperature-control';
import { ShipmentAsset } from './shipment-asset';
import { EntityId } from '@libs/shared/types';
import { BaseModel } from '@libs/shared/models';

export interface LegacyShipment {
  shipmentId: EntityId;
  shipmentExternalId: EntityId;
  carrierVatNumber: string;
  companyId: EntityId;
  stops?: LegacyShipmentStop[];
}

export interface Shipment extends BaseModel {
  clientCompanyId?: EntityId;
  calculatedStart?: number;
  calculatedEnd?: number;
  currentStatus?: ShipmentStatus;
  dispatchers: EntityId[]; // uuids of dispatchers
  documents: ShipmentDocument[];
  driverIds?: EntityId[]; // uuids of drivers
  id: EntityId;
  messages: unknown[]; // for now its unkown, we don't use messages
  ptvStartLocation: any;
  plannedRoute?: {
    polylineURL: string;
    totalMeters: number;
    totalSeconds: number;
  };
  ptvUpdateStart: number;
  shipmentExternalId?: EntityId;
  shipmentId: EntityId;
  statusHistory: ShipmentStatusHistory[]; // in chronological order, the last one is the actual
  stops: ShipmentStop[];

  temperatureControl: ShipmentTemperatureControl;
  tripId?: string;
  vehicle: {
    assets: ShipmentAsset[];
    vehicleProfile: {};
  };
  contractorCompanyId: EntityId;
  contractDate: number;
  /**
   * Optional field representing number of comments for shipment.
   */
  cntCommentUpdate?: number;
}
