import { Pipe, PipeTransform } from '@angular/core';
import { Asset } from '@app-models';

@Pipe({
  name: 'displayNameForTrailer'
})
export class TrailerDisplayNamePipe implements PipeTransform {

  transform(trailer: Asset | null | undefined, ...args: unknown[]): string {
    if (trailer.licencePlate) {
      return trailer.licencePlate;
    } else if (trailer.displayName) {
      return trailer.displayName;
    } else {
      return 'No name';
    }
  }
}
