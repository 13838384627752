import { AdvancedHttpClient, WebDataRepository } from '@libs/shared/data-access';
import { IShipmentRepository } from './IShipmentRepository';
import {
  LegacyShipment,
  LegacyShipmentStopTask,
  Shipment,
  ShipmentStop,
  TaskStatusChangeParams
} from '@app-models';
import { EntityId } from '@libs/shared/types';
import { Observable } from 'rxjs';

export class ShipmentRepository extends WebDataRepository<Shipment> implements IShipmentRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'shipments');
  }

  public getShipmentTasks(shipmentId: EntityId<Shipment>, stopId: EntityId<ShipmentStop>): Observable<LegacyShipmentStopTask[]> {
    return this.httpClient.get({ url: `${this.resource}/${shipmentId}/stops/${stopId}/tasks` });
  }

  public getShipmentsByTruckId(truckId: EntityId): Observable<LegacyShipment[]> {
    return this.httpClient.get({ url: `${this.resource}/byTruckId/${truckId}` });
  }

  public hasShipments(truckId: EntityId): Observable<boolean> {
    return this.httpClient.get({ url: `${this.resource}/byTruckId/${truckId}/hasShipments` });
  }

  public changeShipmentTaskStatus(
    shipmentId: string,
    stopId: string,
    taskId: string,
    params: TaskStatusChangeParams,
  ): Observable<LegacyShipmentStopTask> {
    return this.httpClient.patch({
      url: `${this.resource}/${shipmentId}/stops/${stopId}/tasks/${taskId}`,
      body: params,
    });
  }

  public createConsignmentFile(shipmentId: string, stopId: string, taskId: string, file: File, documentType: string): Observable<void> {
    const data = new FormData();
    data.append('file', file);
    data.append('documentType', documentType);

    return this.httpClient.post({
      url: `${this.resource}/${shipmentId}/stops/${stopId}/tasks/${taskId}/documents`,
      body: data,
    });
  }
}
