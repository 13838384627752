export enum LegacyShipmentStatuses {
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  FINISHED_BY_FOLLOWING_SHIPMENT = 'finished_by_following_shipment',
  ARCHIVE = 'archive',
  ERROR = 'error'
}
