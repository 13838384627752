import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Platform} from '@ionic/angular';
import {AuthService, ChatService, LanguageService, NetworkService, StorageService, ToasterService} from '@app-services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NetworkPopupComponent} from '@app-shared-components';
import {TranslateService} from '@ngx-translate/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {StorageKeys} from '@app-enums';
import {distinctUntilChanged} from 'rxjs/operators';
import {ThemeService, ThemeVariantMode} from '@libs/shared/theme';
import {StatusBar, Style} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';
import {NavigationBar} from '@capgo/capacitor-navigation-bar';
import {IMobileAppEnvironment} from '@app-environments/IMobileAppEnvironment';
import { ENVIRONMENT } from '@libs/shared/environment';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  // properties

  public loggedIn: boolean;
  private networkPopup: MatDialogRef<any> = null;

  constructor(
    private languageService: LanguageService,
    private authService: AuthService,
    private platform: Platform,
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private networkService: NetworkService,
    private chatService: ChatService,
    private themeService: ThemeService,
    private readonly meta: Meta,
    @Inject(ENVIRONMENT) environment: IMobileAppEnvironment
  ) {
    this.meta.addTag({
      name: 'version',
      content: environment.version
    });
    this.init();
  }

  private init() {
    this.themeService.init(ThemeVariantMode.Dark);

    this.platform.ready().then(() => {
      this.languageService.initDefaultLanguage();
      this.setLoggedIn();
      this.networkService.listenForNetworkChanges();
      this.handleNetworkStatusChanges();
      this.setStatusBarColor();
    });
  }

  private setLoggedIn(): void {
    this.authService.refreshToken();
    let onlineTimeStamping;
    this.loggedIn = this.authService.isLoggedIn;
    this.authService.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn !== null) {
        this.loggedIn = loggedIn;
        this.handleCapacitorInit();
      }
      clearInterval(onlineTimeStamping);
      if (this.loggedIn) {
        this.onlineStatusUpdate(onlineTimeStamping);
      }
    });
  }

  private setStatusBarColor() {
    if(this.platform.is('capacitor')) {
      StatusBar.setStyle({
        style: Style.Dark
      });
      StatusBar.setBackgroundColor({
        color: '#152746'
      });
      NavigationBar.setNavigationBarColor({
        color: '#152746'
      });
    }
  }

  private handleCapacitorInit() {
    if(this.platform.is('capacitor')) {
      setTimeout(() => {
        SplashScreen.hide({fadeOutDuration: 200})
          .then(() => {
            setTimeout(() => {
              this.setStatusBarColor();
            }, 200);
          });
      }, 200);
    }
  }

  /**
   * Create a timer to log online status to firebase
   *
   * @param onlineTimeStamping NodeJs.Timeout
   * @returns void
   */
  private onlineStatusUpdate(onlineTimeStamping): void {
    this.updateOnlineStatus();
    onlineTimeStamping = setInterval(() => {
      this.updateOnlineStatus();
    }, 30 * 1000);
  }

  /**
   * Update driver online status,appHeartbeat property
   *
   * @returns void
   */
  private updateOnlineStatus(): void {
    let uid = this.storageService.get(StorageKeys.USER_ID);
    this.afs.doc('/drivers/' + uid).ref.set(
      {
        appHeartbeat: Math.ceil(new Date().getTime() / 1000),
      },
      {
        merge: true,
      },
    );
  }

  /**
   * Listen to network changes, set timestamp from last message when app was online
   * Open/Close network popup
   *
   * @returns void
   */
  private handleNetworkStatusChanges(): void {
    this.networkService.networkStatus$.pipe(distinctUntilChanged()).subscribe((status) => {
      if (!status) {
        this.chatService.updateOfflineTimeStamp();
        this.networkPopup = this.dialog.open(NetworkPopupComponent, { disableClose: false });
      } else if (this.networkPopup) {
        this.networkPopup.close();
        this.toasterService.showMessage(this.translateService.instant('General.Messages.NetworkConnected'));
      }
    });
  }
}
