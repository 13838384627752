import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ToasterService, AuthService, StorageService, NetworkService} from '@app-services';
import { Paths, StorageKeys, ToasterTypes } from '@app-enums';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private interceptExceptions: string[] = ['binary/download/'];

  private readonly unknownError = 'Unknown Error';

    constructor(
        private readonly toasterService: ToasterService,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly translationService: TranslateService,
        private readonly storageService: StorageService,
        private readonly networkService: NetworkService
    ) { }

  /**
   * The interceptor method.
   *
   * @param req - Current request.
   * @param next - HttpHandler.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let skipInterception = false;
        this.interceptExceptions.forEach((x) => {
          if (req.url.includes(x)) {
            skipInterception = true;
          }
        });
        if (error.status === 0 && this.networkService.getCurrentNetworkStatus) {
          // Errors due to missing network connection also have status 0, but should not trigger logout
          skipInterception = true;

          if(error.url.includes('refresh-token')) {
            this.authService.logout();
          } else {
            this.authService.refreshToken();
          }
        }
        if (error.url.includes('logoutFromAsset')) {
          // errors when login out should be ignored, just logout
          skipInterception = true;
        }
        if (skipInterception) {
          return throwError(error.message);
        }

        const errorStatus = error.status;
        // classify by code
        const isClientError = errorStatus % 400 <= 99;
        const isServerError = errorStatus % 500 <= 99;

        if (isClientError) {
          this.handleClientError(error);
        } else if (isServerError) {
          this.handleServerError(error);
        } else {
          this.defaultErrorHandler(error);
        }

        return throwError(error.message);
      }),
    );
  }

  /**
   * Will simply load notify.
   *
   * @param error - Error ocurred.
   */
  private defaultErrorHandler(error: HttpErrorResponse) {
    if (error.statusText !== this.unknownError) {
      this.toasterService.showMessage(error.statusText, ToasterTypes.ERROR);
    } else {
      this.toasterService.showMessage(this.translationService.instant('General.Messages.troubleConnecting'), ToasterTypes.ERROR);
    }
  }

  /**
   * Handle 404 not found error
   *
   * @param error :HttpErrorInterceptor
   */
  private handleNotFoundError(error: HttpErrorResponse) {
    this.toasterService.showMessage(
      error?.error?.message ?? this.translationService.instant('General.Messages.NotFoundError'),
      ToasterTypes.ERROR,
    );
  }

  /**
   * Handle all server errors (5xx).
   *
   * @param error - Error ocurred.
   */
  private handleServerError(error: HttpErrorResponse) {
    this.toasterService.showMessage(error.statusText, ToasterTypes.ERROR);
  }

  /**
   * Handle all client errors (4xx).
   *
   * @param error - Error ocurred.
   */
  private handleClientError(error: HttpErrorResponse) {
    const errorStatus = error.status;
    switch (errorStatus) {
      case 400:
        this.handleValidationError(error);
        break;
      case 401:
        this.handleAuthError(error);
        break;
      case 403:
        this.handleUnauthorizedError(error);
        break;
      case 422:
        this.handleValidationError(error);
        break;
      case 404:
        this.handleNotFoundError(error);
        break;
      default:
        this.defaultErrorHandler(error);
        break;
    }
  }

  /**
   * Authorization error.
   *
   * @param error - Error ocurred.
   */
  private handleAuthError(error: HttpErrorResponse) {
    if(error.url.includes('refresh-token')) {
      this.authService.clearStorageBeforeLogout();
      this.toasterService.showMessage(this.translationService.instant('General.Messages.AuthError'), ToasterTypes.ERROR);
      this.authService.isLoggedIn = false;
      this.router.navigateByUrl(Paths.ROOT_AUTH, { replaceUrl: true });
      this.authService.newUserData = null;
      this.storageService.remove(StorageKeys.USER);
    } else {
      this.authService.refreshToken();
    }
  }

  /**
   * Not authorized.
   *
   * @param error Error that ocurred.
   */
  private handleUnauthorizedError(error: HttpErrorResponse) {
    this.authService.clearStorageBeforeLogout();
    this.router.navigateByUrl(Paths.ROOT_AUTH, { replaceUrl: true });
    this.toasterService.showMessage(error.error.message, ToasterTypes.ERROR);
  }

  /**
   * Form Validation error method, here we'll iterate through each validation message in response
   *
   * @param error - Error ocurred.
   */
  private handleValidationError(error: HttpErrorResponse) {
    this.toasterService.showMessage(error.statusText, ToasterTypes.ERROR);
  }
}
