import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  //#region Methods

  /**
   * Generates and returns login form.
   *
   * @returns FormGroup
   */
  public getLoginForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required]),
      pinCode: new UntypedFormControl(null, [Validators.required]),
    });
  }

  //#endregion
}
