import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private currentNetworkStatus: boolean = true;

  public get getCurrentNetworkStatus(): boolean {
    return this.currentNetworkStatus;
  }

  public networkStatus$ = new BehaviorSubject<boolean>(true);

  constructor() { }

  /**
   * Subscribe to internet connection changes
   *
   * @returns void
   */
  public listenForNetworkChanges(): void {
    Network.addListener('networkStatusChange', async status => {
      this.currentNetworkStatus = status.connected;
      this.networkStatus$.next(status.connected);
    });
  }

}
