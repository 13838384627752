import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectedTruckService } from '@app-services';
import * as firebase from 'firebase/compat/app';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { take } from 'rxjs/operators';
import { Asset } from '@app-models';

@Injectable({
  providedIn: 'root'
})
export class TrailerService {

  constructor(
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private selectedTruckService: SelectedTruckService,
  ) { }

  /**
   * Get trailer details base on it's ID.
   *
   * @param trailerId: string
   *
   * @returns Observable<any>
   */
  public getTrailerDetails(trailerId?: string): Observable<Asset> {
    const trailerAssetId = trailerId ? trailerId : this.selectedTruckService.trailerId;
    return this.afs
      .doc<Asset>(`/assets/${trailerAssetId}/`)
      .valueChanges();
  }

  /**
   * Get Trailer images based on Trailer ID.
   *
   * @param trailerId: string
   *
   * @returns Observable<any>
   */
  public getTrailerImages(trailerId?: string): Observable<any> {
    const trailerAssetId = trailerId ? trailerId : this.selectedTruckService.trailerId;
    return this.afs
      .collection(
        `/assets/${trailerAssetId}/files`,
        (ref) => ref.orderBy('datetime', 'desc').limit(50)
      )
      .valueChanges();
  }

  /**
   * Attach the trailer to the current truck.
   *
   * @param trailerId string
   *
   * @returns Promise<any>
   */
  public attachTrailer(trailerId: string): Promise<any> {
    const attachBatch = this.afs.firestore.batch();
    attachBatch.set(
      this.afs.doc(`/assets/${this.selectedTruckService.truckId}/`).ref,
      { assetIDAfter: trailerId },
      { merge: true }
    );
    attachBatch.set(
      this.afs.doc(`/assets/${trailerId}/`).ref,
      { assetIDBefore: this.selectedTruckService.truckId },
      { merge: true }
    );
    return attachBatch.commit();
  }
  /**
   * Detach the trailer from the current truck.
   * Trailer Id should be provided as a method parameter,
   * otherwise it will be taken as the last selected Trailer ID
   * from selected-truck-service which is handling Trailer info.
   *
   * @param trailerId string | null
   *
   * @returns Promise<any>
   */
  public detachTrailer(trailerId?: string): Promise<any> {
    const detachBatch = this.afs.firestore.batch();
    detachBatch.set(
      this.afs.doc(`/assets/${this.selectedTruckService.truckId}/`).ref,
      { assetIDAfter: firebase.default.firestore.FieldValue.delete() },
      { merge: true }
    );
    const trailerAssetId = trailerId ? trailerId : this.selectedTruckService.trailerId;
    detachBatch.set(
      this.afs.doc(`/assets/${trailerAssetId}/`).ref,
      { assetIDBefore: firebase.default.firestore.FieldValue.delete() },
      { merge: true }
    );
    return detachBatch.commit();
  }

  /**
   *
   *
   * @param base64ImageSource any
   *
   * @returns any
   */
  public takeTrailerPhoto(base64ImageSource: string, assetId: string, userId: string, base64thumb: string) {

    const destPath = `assistant/documents/${assetId}/trailerphoto-${Math.floor(new Date().getTime() / 1000)}.jpg`;
    const fileRef = this.afStorage.ref(destPath);
    const task = this.afStorage.ref(destPath).putString(base64ImageSource, 'data_url');
    const docID = this.afs.createId();

    const destPathThumb = `assistant/documents/${assetId}/trailerphoto-${Math.floor(new Date().getTime() / 1000)}-thumb.jpg`;
    const fileRefThumb = this.afStorage.ref(destPathThumb);
    const taskThumb = this.afStorage
      .ref(destPathThumb)
      .putString(base64thumb, 'data_url');

    const batch = this.afs.firestore.batch();
    return Promise.all([task, taskThumb])
      .then(async (results: any[]) => {
        const url = await fileRef.getDownloadURL().pipe(take(1)).toPromise();
        const urlThumb = await fileRefThumb
          .getDownloadURL()
          .pipe(take(1))
          .toPromise();

        this.afs
          .doc(`/assets/${assetId}/files/${docID}`)
          .set({
            fileUrl: url,
            thumbnailUrl: urlThumb,
            datetime: Math.floor(new Date().getTime() / 1000),
            senderDriverID: userId,
          });
      });
  }

}
