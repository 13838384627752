import { Injectable } from '@angular/core';
import { LoaderConsumersKeys, ToasterTypes } from '@app-enums';
import { ChatService, LoaderService, ToasterService } from '@app-services';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import { ConversionService } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private chatHttpService: ChatService,
    private conversionService: ConversionService,
  ) {}

  /**
   * Create PDF in A4 format, and add list of images to each page of the document.
   * PDF document in base64 format will be returned through callback.
   *
   * @param base64Images string[]
   *
   * @returns Promise<string>
   */
  public async createPdfFromImages(base64Images): Promise<string> {
    let pdf = new jsPDF({
      unit: 'px',
      hotfixes: ['px_scaling'],
    });
    let imageCounter = 0;
    for (const base64Image of base64Images) {
      let tempImage = new Image();
      await new Promise((resolve) => {
        tempImage.onload = async () => {
          imageCounter++;
          pdf.addPage([tempImage.width, tempImage.height], tempImage.height < tempImage.width ? 'l' : 'p');
          pdf.addImage(base64Image.changingThisBreaksApplicationSecurity ?? base64Image, 'jpeg', 0, 0, tempImage.width, tempImage.height, '', 'FAST');

          resolve('resolved');
        };
        tempImage.src = base64Image.changingThisBreaksApplicationSecurity ?? base64Image;
      });
    }
    pdf.addPage([500, 500]);
    pdf.deletePage(1);
    pdf.deletePage(base64Images.length + 1);

    return pdf.output('datauristring').toString();
  }

  /**
   * Uploads PDF to Chat.
   *
   * @param base64PdfDocument string
   * @param mode string
   * @param noticeText string
   *
   * @returns void
   */
  public async uploadPdf(base64PdfDocument: string, mode: string, noticeText: string): Promise<void> {
    const file = await this.conversionService.convertToFile(base64PdfDocument, 'application/pdf');

    this.chatHttpService.sendMessage({
      fileName: file.name,
      file,
      text: noticeText ?? '',
      imageType: mode,
      language: this.translateService.currentLang
    }).subscribe({
      next: () => {
        this.loaderService.hideLoader(LoaderConsumersKeys.PDF_SERVICE);
      },
      error: () => {
        this.loaderService.hideLoader(LoaderConsumersKeys.PDF_SERVICE);
        this.toasterService.showMessage(
          this.translateService.instant('General.AnErrorOcurred'),
          ToasterTypes.ERROR
        );
      }
    });

  }
}
